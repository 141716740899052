import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { FirstScreen } from './FirstScreen';
import { ThirdScreen } from './ThirdScreen';

import arrowBack from '../../assets/play-circle.svg';
import Logo from '../ui/Header/Logo/Logo';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';

import TypeWriterEffect from 'react-typewriter-effect';

export const SecondScreen = () => {

    const [back, setBack] = useState(false);
    const [startQuestions, setStartQuestions] = useState(false);
    const [next, setNext] = useState(false);
    const [selected, setSelected] = useState('');
    const { dispatch } = useContext(AuthContext);

    useEffect(
        () => {
            let timer1 = setTimeout(() => autoNextQuestion(), 3000);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const autoNextQuestion = () => {
        setStartQuestions(!startQuestions);
    }


    const goBack = (e) => {
        e.preventDefault();
        setBack(!back);
    }

    const start = (e) => {
        e.preventDefault();
        setStartQuestions(!startQuestions);
    }

    const setAge = (value) => {
        setSelected(value);

        setTimeout(() => {
            setNext(!next);
        }, 500);

        dispatch({
            type: types.saveTest,
            payload: {
                ageRange: value
            }
        })
    }

    return (
        <div>

            {
                back &&
                <FirstScreen />
            }

            {

                <div>

                    {
                        !next &&


                        <div className="otro-container">
                            {
                                !back &&
                                <>
                                    <div className="test__logo">
                                        <LogoWhite />
                                    </div>
                                    <img
                                        className="test__back-button-screen"
                                        onClick={goBack}
                                        src={arrowBack}
                                        alt="Back"
                                    />
                                </>
                            }

                            <div className="test__first-section-yellow">
                                <h3 className="test__title-description">¿Qué edad tenés?</h3>
                                <p>Nos ayudará a determinar si podemos tratarte.</p>
                            </div>

                            <div className="test__row">
                                <div
                                    className='test__option'
                                    onClick={() => setAge('menos-16')}
                                >
                                    <div
                                        className={selected === 'menos-16' ? 'test__first-active-yellow' : 'yellow-hover'}>Menos de 16</div>
                                </div>
                                <div
                                    className='test__option'
                                    onClick={() => setAge('16-17')}
                                >
                                    <div
                                        className={selected === '16-17' ? 'test__first-active-yellow' : 'yellow-hover'}>
                                        16-17</div>
                                </div>
                                <div
                                    className='test__option'
                                    onClick={() => setAge('18-25')}
                                >
                                    <div
                                        className={selected === '18-25' ? 'test__first-active-yellow' : 'yellow-hover'}> 18-25</div>
                                </div>
                            </div>

                            <div className="test__row">

                                <div
                                    className='test__option'
                                    onClick={() => setAge('26-35')}
                                >
                                    <div
                                        className={selected === '26-35' ? 'test__first-active-yellow' : 'yellow-hover'}>26-35</div>
                                </div>
                                <div
                                    className='test__option'
                                    onClick={() => setAge('36-45')}
                                >
                                    <div
                                        className={selected === '36-45' ? 'test__first-active-yellow' : 'yellow-hover'}> 36-45</div>
                                </div>
                                <div
                                    className='test__option'
                                    onClick={() => setAge('46+')}
                                >
                                    <div
                                        className={selected === '46+' ? 'test__first-active-yellow' : 'yellow-hover'}> 46+</div>
                                </div>
                            </div>

                            <p className="test__paragraph-select-option">Elegí una respuesta para continuar</p>
                        </div>
                    }


                    {
                        next &&
                        <ThirdScreen />
                    }
                </div>
            }

        </div>
    )
}
