import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { FourthScreen } from './FourthScreen';
import { SixthScreen } from './SixthScreen';
import { EighthScreen } from './EighthScreen';
import arrowBack from '../../assets/play-circle.svg';
import playCircleNext from '../../assets/play-circle-lightblue.svg';
import Logo from '../ui/Header/Logo/Logo';
import TypeWriterEffect from 'react-typewriter-effect';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';
export const FifthQuestion = () => {

    const [question, setQuestion] = useState(false);
    const [back, setBack] = useState(false);
    const [next, setNext] = useState(false);
    const [selected, setSelected] = useState([]);
    const { dispatch } = useContext(AuthContext);

    useEffect(
        () => {
            let timer1 = setTimeout(() => autoNextQuestion(), 0);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const autoNextQuestion = () => {
        setQuestion(!question);
    }

    const setAd = (resp) => {
        let selectedOption = selected;
        selectedOption.push(resp);
        setSelected(selectedOption);

        dispatch({
            type: types.saveTest,
            payload: {
                adFrom: resp
            }
        });
    }

    const goBack = (e) => {
        e.preventDefault();
        setBack(true);
    }

    const nextPage = (e) => {
        e.preventDefault();
        setNext(!next);
    }

    return (
        <div>
            {
                question && !back && !next &&
                <div >
                    <div className="test__logo">
                        <LogoWhite />
                    </div>
                    <img
                        className="test__back-button-screen"
                        onClick={goBack}
                        src={arrowBack}
                        alt="Back"
                    />

                    <div className="test__first-section-lightblue">
                        <h3>¿Cómo nos conociste?</h3>
                        <p>Elegí las que apliquen para continuar</p>
                    </div>
                    <div>
                        <div className="test__fifth-row">
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('amigos-familiares')}
                            >
                                <div
                                    className={selected.includes('amigos-familiares') ? 'test__first-active-lightblue' : 'test__fifth-item'}> Amigos o familiares</div>
                            </div>
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('influencer')}
                            >
                                <div
                                    className={selected.includes('influencer') ? 'test__first-active-lightblue' : 'test__fifth-item'}>  Influencer</div>
                            </div>
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('facebook')}
                            >
                                <div
                                    className={selected.includes('facebook') ? 'test__first-active-lightblue' : 'test__fifth-item'}> Facebook</div>
                            </div>
                        </div>

                        <div className="test__fifth-row">
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('instagram')}
                            >
                                <div
                                    className={selected.includes('instagram') ? 'test__first-active-lightblue' : 'test__fifth-item'}> Instagram </div>
                            </div>
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('busqueda-online')}
                            >
                                <div
                                    className={selected.includes('busqueda-online') ? 'test__first-active-lightblue' : 'test__fifth-item'}>  Búsqueda online </div>
                            </div>
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('youtube')}
                            >
                                <div
                                    className={selected.includes('youtube') ? 'test__first-active-lightblue' : 'test__fifth-item'}>   YouTube </div>
                            </div>
                            <div
                                className='test__fifth-item-container'
                                onClick={() => setAd('otros')}
                            >
                                <div
                                    className={selected.includes('otros') ? 'test__first-active-lightblue' : 'test__fifth-item'}>  Otros </div>
                            </div>
                        </div>


                        <div
                            className="test__next-button-fifth"
                            onClick={nextPage}
                        >
                            <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Siguiente</p>
                            <img src={playCircleNext} alt="PlayCircleNext" />
                        </div>
                    </div>
                </div>
            }

            {
                next &&
                <EighthScreen />
            }
        </div>
    )
}
