import promiseMap from 'p-map';
import { getUserLocation } from './lib/utils';
import React, { Component } from 'react';
/* import DirectionIcon from './DirectionIcon'; */
import SearchIcon from './SearchIcon';
import axios from 'axios';
import './StoreLocator.css';
/* import WebIcon from './WebIcon'; */

// const travelModes = {
//   DRIVING: 'car',
//   WALKING: 'walk'
// };

const units = {
  METRIC: 0,
  IMPERIAL: 1
};

const toMiles = 1.609;
const API_PATH_LOGIN = 'http://66.97.35.53/lineup/api/auth/login'
const API_PATH_CONSULTORIOS = 'http://66.97.35.53/lineup/api/consultorios'
const google = window.google = window.google ? window.google : {};

let OpenInfowindows = [];



export class StoreLocator extends Component {
  static defaultProps = {
    stores: [],
    zoom: 16,
    limit: 10,
    center: { lat: -34.593998, lng: -58.401892 },
    travelMode: 'DRIVING',
    homeLocationHint: 'Current location',
    homeMarkerIcon: '../map/house.png',
    storeMarkerIcon: '../map/storeIcon.png',
    unitSystem: 'METRIC',
    farAwayMarkerOpacity: 0.3,
    fullWidthMap: false
  };

  constructor(props) {
    super(props);
    this.state = {
      searchLocation: null,
      activeStoreId: null,
      //stores: this.getConsultorios(),
      //stores: this.addStoreIds(this.getConsultorios()),
      stores: this.addStoreIds(props.stores),
      searchHint: props.searchHint
    };
    this.markers = [];
    this.ActiveInfoWindow = undefined;

    /*     console.log(props);
        console.log(props.searchHint); */
  }

  getConsultorios(){
    let consultorios = []
    let data = {
      "email": "test@test.com",
      "password": "integrah"
    }
    axios({
      method: 'post',
      url: `${API_PATH_LOGIN}`,
      withCredentials: true,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }).then(result => {
        /* {
            name: "Adriana Andrea Miño",
            address: "Diagonal 74 y 120 nro 25, La Plata  - 221-4206237",
            location: {lat: -34.88972985332981, lng: -57.95608673358858},
            photo: FotoEsp1
        }*/
        axios({
          method: 'get',
          url: `${API_PATH_CONSULTORIOS}`,
          withCredentials: true,
          crossdomain: true,
          headers: {
            'Authorization': `Bearer ${result.data.data.token}`,
            'Content-Type': 'application/json'
          },
        }).then(result => {
           
            consultorios = result.data.data.consultorios
            console.log(consultorios)
            let aux = []
            return consultorios.map(c => {
              let data ={
                "name": c.odontologo.nombre,
                "address": "Diagonal 74 y 120 nro 25, La Plata  - 221-4206237",
                "location": {lat: c.latitud, lng: c.longitud},
                "photo": "FotoEsp1"
              }
              aux.push(data)
              return aux   
            })           
          }).catch(error => console.log(error.message));
      })
      .catch(error => console.log(error.message));   
  }


  addStoreIds(stores = []) {
 
    return stores.map((store, i) => {
      store.id = store.id || i;
      return store;
    });
  }

  async loadGoogleMaps() {
    /*     if (window.google && window.google.maps) return Promise.resolve();
        return loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}&libraries=geometry,places`
        ); */
  }

  loadStores = async searchLocation => {
    if (!this.props.loadStores) return this.state.stores;
    let stores = await this.props.loadStores(searchLocation);
    stores = this.addStoreIds(stores);
    //let stores = await this.getConsultorios()
    //console.log(stores)
    this.setState({ stores });
    return stores;
  };

  getMarkerIcon(icon) {
    if (!icon) return null;
    const { markerIconSize } = this.props;
    if (typeof icon === 'string' && markerIconSize) {
      const iconSize = markerIconSize;
      return {
        url: icon,
        scaledSize: new google.maps.Size(iconSize[0], iconSize[1])
      };
    }
    return icon;
  }

  addStoreMarker = store => {
    const infoWindow = new google.maps.InfoWindow({
      content: `<div  class=infoWindow' style='max-width: 400px'>
          <h4 style='font-size:1.3rem; padding-bottom:1rem'>${store.name}</h4>
          ${store.address}
        </div>`
    });
    const marker = new google.maps.Marker({
      position: store.location,
      title: store.name,
      map: this.map,
      icon: this.getMarkerIcon(this.props.storeMarkerIcon)
    });
    marker.addListener('click', () => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      infoWindow.open(this.map, marker);
      this.infoWindow = infoWindow;
      this.ActiveInfoWindow = infoWindow;
      this.setState({ activeStoreId: store.id });
    });


    OpenInfowindows[store.id] = () => {
      if (this.ActiveInfoWindow) {
        this.ActiveInfoWindow.close();
      }

      infoWindow.open(this.map, marker);
      this.ActiveInfoWindow = infoWindow;
      console.log(this.ActiveInfoWindow);
    }

    this.markers.push(marker);
    return marker;
  };

  async getDistance(p1, p2) {
    const origin = new google.maps.LatLng(p1);
    const destination = new google.maps.LatLng(p2);
    const directDistance = this.getDirectDistance(origin, destination);
    return new Promise(resolve => {
      this.distanceService.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: this.props.travelMode,
          unitSystem: units[this.props.unitSystem],
          durationInTraffic: true,
          avoidHighways: false,
          avoidTolls: false
        },
        (response, status) => {
          if (status !== 'OK') return resolve(directDistance);
          const route = response.rows[0].elements[0];
          if (route.status !== 'OK') return resolve(directDistance);
          resolve({
            distance: route.distance.value,
            distanceText: route.distance.text,
            durationText: route.duration.text
          });
        }
      );
    });
  }

  getDirectDistance(origin, destination) {
    const distance =
      google.maps.geometry.spherical.computeDistanceBetween(origin, destination) / 1000;
    if (units[this.props.unitSystem] === 1) {
      return {
        distance: distance / toMiles,
        distanceText: `${(distance / toMiles).toFixed(2)} mi`
      };
    }
    return {
      distance,
      distanceText: `${distance.toFixed(2)} km`
    };
  }

  setHomeMarker(location) {
    if (this.homeMarker) {
      this.homeMarker.setMap(null);
    }
    const infoWindow = new google.maps.InfoWindow({
      content: this.props.homeLocationHint
    });
    this.homeMarker = new google.maps.Marker({
      position: location,
      title: this.props.homeLocationHint,
      map: this.map,
      icon: this.getMarkerIcon(this.props.homeMarkerIcon)
    });
    this.homeMarker.addListener('click', () => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      infoWindow.open(this.map, this.homeMarker);
      this.infoWindow = infoWindow;
    });
  }

  setupMap = async () => {
    const { center, zoom } = this.props;
    this.map = new window.google.maps.Map(this.mapFrame, {
      center,
      zoom,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    });
    this.distanceService = new google.maps.DistanceMatrixService();
    const geocoder = new google.maps.Geocoder();
    this.setupAutocomplete();
    this.state.stores.map(this.addStoreMarker);
    const location = await getUserLocation();
    this.setState({ searchLocation: location });
    this.calculateDistance(location);
    this.map.setCenter(location);
    this.map.setZoom(11);
    this.setHomeMarker(location);

    geocoder.geocode({ location: location }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.input.value = results[0].formatted_address;
        }
      }
    });
  };

  setupAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.input);
    autocomplete.bindTo('bounds', this.map);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(11);
      }
      const location = place.geometry.location.toJSON();
      this.setState({ searchLocation: location });
      this.setHomeMarker(location);
      this.calculateDistance(location);
    });
  }

  clearMarkers() {
    this.markers.forEach(m => {
      m.setMap(null);
    });
    this.markers = [];
  }

  async calculateDistance(searchLocation) {
    const { limit } = this.props;
    if (!searchLocation) return this.props.stores;
    const stores = await this.loadStores(searchLocation);
    const data = await promiseMap(stores, store => {
      return this.getDistance(searchLocation, store.location).then(result => {
        Object.assign(store, result);
        return store;
      });
    });
    let result = data.sort((a, b) => a.distance - b.distance);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(searchLocation);
    this.clearMarkers();
    result = result.map((store, i) => {
      store.hidden = i + 1 > limit;
      const marker = this.addStoreMarker(store);
      if (store.hidden) {
        marker.setOpacity(this.props.farAwayMarkerOpacity);
      } else {
        bounds.extend(store.location);
      }
      return store;
    });
    this.map.fitBounds(bounds);
    this.map.setCenter(bounds.getCenter(), this.map.getZoom() - 1);
    this.setState({ stores: result });
  }


  componentDidMount() {
    this.loadGoogleMaps()
      .then(this.loadStores)
      .then(this.setupMap);
  }

  onStoreClick(store) {
    this.map.setCenter(store.location);
    this.map.setZoom(16);
    this.setState({ activeStoreId: store.id });
    /*     console.log(store) */
    // let marker = this.markers.filter(obj => {
    //   return obj.title === store.name
    // })

    OpenInfowindows[store.id]();
  }

  //noinspection JSCheckFunctionSignatures
  render(searchHint, travelMode, fullWidthMap, activeStoreId, stores) {
    /* console.log (this.props.stores); */
    return (
      <div>
        <div className="container-map fullWidthMap">
          <div className="searchBox">
            <div className="searchInput">
              <input type="text" ref={input => (this.input = input)} />
              <SearchIcon className="searchIcon" />
            </div>
            {searchHint && <div className="searchHint">{searchHint}</div>}
            <ul className="storesList">
              {this.state.stores.map(store => {
                // const locationStr = `${store.location.lat},${store.location.lng}`;
                return (
                  <li
                    key={store.id}
                    onClick={() => this.onStoreClick(store)}
                    className={store.id === this.state.activeStoreId ? "activeStore hiddenStore" : "hiddenStore"
                      /*                     [classNames.activeStore]: store.id === activeStoreId,
                                          [classNames.hiddenStore]: store.hidden */
                    }
                  >
                    <h4>{store.name}</h4>
                    {store.distanceText && (
                      <div className="storeDistance">
                        {' - '} {store.durationText} de distancia. ({store.distanceText})
                        {/*             {store.durationText &&
                        `(${store.durationText} by ${travelModes[travelMode]})`} */}
                      </div>
                    )}
                    <address>{store.address}</address>
                    {/* <img src={store.photo} style={{ marginTop: '1rem', width: '100%' }} alt='foto' className={store.id === this.state.activeStoreId ? "showFoto" : "hiddenFoto"} /> */}
                    {/*                   <div className={classNames.storeActions} onClick={e => e.stopPropagation()}>
                    <a target="_blank" href={`https://www.google.com/maps?daddr=@${locationStr}`}>
                      <DirectionIcon />
                      directions
                    </a>{' '}
                    {store.website && (
                      <a target="_blank" href={store.website}>
                        <WebIcon />
                        website
                      </a>
                    )}
                  </div> */}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="map" ref={mapFrame => (this.mapFrame = mapFrame)} />
        </div>
      </div>
    );
  }
}
