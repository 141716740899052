

import React, { useRef, useEffect, useState } from 'react';
import BarraMulticolor from '../../assets/BarraMulticolor.jpg';
import '../../styles/components/Policy/_policy.scss';
import { Footer } from '../Footer/Footer';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';




export default function Policy() {
  const [datos, setDatos] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const carouselRef = useRef(null);
  let resetTimeout;







  class AccordionItem extends React.Component {
    state = {
      opened: false
    }

    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this

      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }
  // Simula la carga asíncrona de datos
  useEffect(() => {
    // Tu lógica para cargar datos
    // Ejemplo: fetch('/api/datos').then(response => response.json()).then(data => setDatos(data));
    // Asegúrate de establecer totalPages también
  }, []); // El segundo parámetro es una dependencia vacía, por lo que este efecto se ejecutará solo después del montaje inicial

  useEffect(() => {
    // Este efecto se ejecutará cuando cambien los datos o totalPages
    if (datos && totalPages > 0) {
      // Ahora podemos acceder a carouselRef.current sin preocuparnos por null
      // Realiza operaciones con carouselRef aquí
      // ...

      // Por ejemplo, podrías usar un timeout para simular la espera antes de ejecutar goTo(0)
      resetTimeout = setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(0);
        }
      }, 1500);
    }

    // Limpia el timeout al desmontar el componente o cuando cambie la dependencia
    return () => {
      clearTimeout(resetTimeout);
    };
  }, [datos, totalPages]);






  return (


    <div>
      <Helmet>
        <title>Line Up Argentina | Nuestro Sistema de Alineadores Invisibles</title>
        <meta name="description" content="A través de placas transparentes, casi invisibles, tus dientes se alinearán sin dolor ni tratamientos complicados. Disfrutá de tu sonrisa sin preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/por-que-line-up" />
      </Helmet>
      <main>
        <div className="intro">
          <div className="intro__content">
            <br></br>
            <br></br>
            <h2 className="little1_title">Política de Calidad
            </h2>
            <br></br>
            <br></br>
            <p className="plus1_text">Somos una empresa joven innovadora en el mercado de la ortodoncia; nos dedicamos al diseño y fabricación de alineadores dentales invisibles, teniendo por objetivo principal posicionarnos a nivel nacional como la opción primordial para los especialistas de ortodoncia y los pacientes.</p>
            <br></br>
            <p className="plus1_text">Trabajamos día a día brindando productos de alta calidad.<br></br>
              Nuestra misión es satisfacer las necesidades de nuestros clientes y fidelizarlos, proporcionando a los especialistas una solución confiable y conveniente para mejorar la calidad de sus servicios y la experiencia de sus pacientes.
            </p>
            <br></br>
            <p className="plus1_text">
              Buscamos que nuestros clientes obtengan los mejores resultados, creando riqueza para la sociedad en general, alineada a la experiencia única de utilizar ortodoncia invisible.            </p>
            <br></br>
            <p className="plus1_text">
              Somos conscientes del impacto ambiental asociado con la producción de alineadores y nos esforzamos por minimizarlo. Nos comprometemos a utilizar materiales sostenibles y a seguir prácticas responsables en todos los aspectos de nuestro negocio.
            </p>
            <br></br>
            <p className="plus1_text">Asumimos el compromiso de cumplir con los requisitos aplicables, de nuestros clientes y partes interesadas, y de mejorar continuamente la eficacia del sistema de gestión de calidad de acuerdo a ISO 9001-2015.  </p>
            <br></br>
            <p className="plus1_text_end">Nicolás Diulio<br></br>
              Director
            </p>
          </div>
        </div>
        <br></br>
        <div >
          <img src={BarraMulticolor} alt="Hands" />
        </div>

        <Footer />
      </main>
    </div>
  );
}
