import React from 'react';
import { useEffect } from 'react';
import { useMousePosition } from './useMousePosition';
import { Link } from 'react-router-dom';
import Especialistas from '../../assets/especialistas_unfilled.svg';
import EspecialistasF from '../../assets/especialistas_filled.svg';
import Foto1 from '../../assets/especialistas1.jpg';
import Foto2 from '../../assets/especialistas2.jpg';
import Foto3 from '../../assets/especialistas3.jpg';
import Foto4 from '../../assets/nuevopack.jpg';
import Foto5 from '../../assets/especialistas5.jpg';
import Foto6 from '../../assets/especialistas6.jpg';

import Icon1 from '../../assets/slider_especialistas_icon1.svg';
import Icon2 from '../../assets/slider_especialistas_icon2.svg';
import Icon3 from '../../assets/slider_especialistas_icon3.svg';
import Icon4 from '../../assets/slider_especialistas_icon4.svg';
import Icon5 from '../../assets/slider_especialistas_icon5.svg';
import Icon6 from '../../assets/slider_especialistas_icon6.svg';
import Icon7 from '../../assets/slider_especialistas_icon7.svg';
import Icon8 from '../../assets/slider_especialistas_icon8.svg';
import Icon9 from '../../assets/slider_especialistas_icon9.svg';




import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';
import Carousel, { consts } from 'react-elastic-carousel';
import UsersReviews from './UsersReviews';
import { Footer } from '../Footer/Footer';
import { SocialMedia } from '../HomeScreen/SocialMedia';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';


export default function NuestrosEspecialistas() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const position = useMousePosition();
  useEffect(() => {

    const spotlight = document.querySelector('.spotlight');
    const spoty = spotlight.getBoundingClientRect().top;
    const spotyf = spotlight.getBoundingClientRect().bottom;

    if (position.y > (spoty + 40) && position.y < (spotyf - 40)) {
      let avance = 180 / 40;
      var newSize;
      const diff = (spotyf + spoty) / 2;

      if (position.y < (diff)) {
        console.log('aca');
        newSize = (position.y - (spoty + 40)) * avance;
      }
      if (position.y > (diff)) {
        newSize = ((spotyf - 40) - position.y) * avance;
      }
      if (newSize > 180) {
        newSize = 180;
      }
      /*      console.log (`spoty: ${spotyf}, spotyf: ${spotyf}, position.y ${position.y},newSize: ${newSize}, diff: ${diff}, spoty + diff:  ${spoty + diff}, spotyf - diff:${spotyf - diff} `);
       */

      /*       if (position.y < spoty && position.y > (spoty + diff)) {
             console.log (avance, size, newSize);
              newSize =( ( (spoty - 30) - position.y ) ) * avance;
           }  */

      /* console.log (avance, size, newSize); */
      spotlight.style.backgroundSize = `90%, ${newSize}px, 90%`;
    }

    if (position.y > spoty && position.y < spotyf) {
      spotlight.style.backgroundPosition = `left, ${(position.x - (newSize / 2))}px ${(position.y - spoty) - (newSize / 2)}px, left `;
    } else {
      if (window.innerWidth < 1650) {
        spotlight.style.backgroundPosition = `left, 70% 75%, left `;
      } else {
        spotlight.style.backgroundPosition = `left, 600px 75%, left `;
      }
    }



  });

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 6 },
  ];


  return (
    <div>
      <Helmet>
        <title>Line Up Argentina | Convertite en Experto en Estética Dental</title>
        <meta name="description" content="Te acompañamos con todo lo que necesitas en tus tratamientos y en el desarrollo de tu negocio. ¡Convertite en un experto en alineadores invisibles Line Up!" />
        <link rel="canonical" href="https://line-up.com.ar/nuestros-especialistas" />
      </Helmet>
      <main className="home__container">
        <section className="especialistas__section">
          <nav className="breadcumb-container" >

            <h1 className="breadcumb">Nuestros Especialistas en placas transparentes</h1>

          </nav>
          <h2 className="especialistas__title spotlight spot_desktop"><img src={Especialistas} className="espeimg" alt="Especialistas" /></h2>
          <h2 className="especialistas__title spot_mobile">Queremos que<br /> seas parte del <br />movimiento que<br />revoluciona la<br />ortodoncia</h2>
          <br></br>
          <div className="especialistas__fotos">
            <div className="especialistas__divfoto1">
              <img className="especialistas__foto1" src={Foto1} alt="" />
            </div>

            <div className="especialistas__divfoto2">
              <div className="especialistas__divfotoizq">
                <p> <span>Nuestra misión es clara y transparente como los alineadores. </span></p>
                <p>Contamos con los últimos avances de la tecnología en salud dental para derribar los mitos y hacer visibles las ventajas de un producto de calidad internacional, con el aval de la comunidad científica y apoyado por un equipo de especialistas en ortodoncia.
                  <br /><br />
                  Por eso queremos que nuestros pacientes y especialistas, en el momento de elegirnos, formen parte de un proceso integral que les brinde comodidad y les ayude día a día a superar sus metas.
                </p>
              </div>
              <div className="especialistas__divfotoder">
                <img className="especialistas__foto2" src={Foto2} alt="" />
                <div className="especialistas__deco1container especialistas__deco2container">
                </div>
              </div>

            </div>

          </div>

        </section>

        <section className="especialistas__section">
          <div className="overtips">
            <div className="especialistas__deco1container overtips__deco1container"></div>
            <p className="overtips__text--der overtips1">Tenemos la tecnología para realizar impresiones de modelos en alta definición que adaptan y optimizan los movimientos ortodónticos.</p>
            <div className="especialistas__deco1container overtips__deco1container"></div>
            <div className="especialistas__divfoto2 especialistas__divfoto3">
              <div><img className="especialistas__foto2 especialistas__foto3" src={Foto3} alt="" />
              </div>
              <p className="overtips__text--der overtips2">Mantenemos una comunicación constante para que puedas despejar cualquier duda que surja en los tratamientos.</p></div>
            <div className="especialistas__deco1container overtips__deco2container"></div>

            <div className="especialistas__divfoto2 especialistas__divfoto3 especialistas__divfoto4"><p className="overtips__text--der overtips3">Te facilitamos los procesos de intercambio a través de packagings específicos que van a ordenar tu consultorio.</p><div style={{ textAlign: 'right' }}><img className="especialistas__foto2 especialistas__foto3" src={Foto4} alt="" /></div></div>
            <div className="especialistas__deco1container especialistas__deco2container overtips__deco4container"></div>
            <div className="especialistas__divfoto2 especialistas__divfoto3 especialistas__divfoto5"><div className="especialistas__foto3cont" ><img className="especialistas__foto2 especialistas__foto3" src={Foto5} alt="" /></div><p className="overtips__text--der overtips__text--der5 overtips4">Y te brindamos toda la información necesaria para que puedas comunicar de la mejor forma todo lo que estás haciendo junto a Line Up.</p></div>
            <div className="especialistas__deco1container "></div>
          </div>
        </section>
        <section className="especialistas__section parte">
          <div className="parte__imagen"><img className="parte__img" src={Foto6} alt="" /></div>
          <div className="parte__texto">
            <h3>Formá parte de nuestro staff</h3>
            <h2>¡Convertite en un experto en<br /> alineadores invisibles Line Up!</h2>
            <p>No solo vamos a darte todo lo necesario para llevar a cabo los tratamientos con los alineadores, sino que también te acompañaremos en tu camino profesional con herramientas para el desarrollo de tu negocio.<br /><br /> Con la ayuda de una <span>plataforma online de gestión</span>, vas a formar parte de un proceso integral junto a tus pacientes.<br /><br /> Este espacio unifica y agiliza los intercambios necesarios para el desarrollo de los tratamientos: dar de alta, notificar citas, seguimiento y mucho más.</p>
            <Link
              className="button--violet parte__button"
              to="/sumate"
            >
              Empezá ahora
            </Link>
          </div>
        </section>
        <div className="porque__information especialistas__information ">
          <div className="porque__information-question">¿Por qué ser un especialista Line Up?</div>

          <Carousel itemsToShow={5} breakPoints={breakPoints} renderArrow={myArrow}>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon1} alt="" /></div>
              <h3>Respaldo técnico y profesional</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon2} alt="" /></div>
              <h3>Capacitación permanente</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon3} alt="" /></div>
              <h3>Mayor rentabilidad</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon4} alt="" /></div>
              <h3>Menos tiempo de sillón</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon5} alt="" /></div>
              <h3>Tiempo preciso de tratamiento</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon6} alt="" /></div>
              <h3>Visibilidad del consultorio o clínica </h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon7} alt="" /></div>
              <h3>Soporte para comunicar en redes sociales</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon8} alt="" /></div>
              <h3>Más opciones de tratamientos</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon9} alt="" /></div>
              <h3>Sistema digital con poca inversión</h3>
            </div>
          </Carousel>
        </div>

        <div className="especialistas_review_title">Especialistas que eligieron Line Up</div>

        <UsersReviews></UsersReviews>

        <section className="especialistas__section formate">
          <h2 className="formate__titulo">Seguí tu formación con nosotros</h2>
          <p className="formate__texto">Encontrá en Line Up un espacio de capacitación constante y una nueva forma de llegarles a tus pacientes. Sumate a nuestra red de ortodoncistas y actualizá tu carrera profesional.</p>
          <Link
            className="button--violet formate__button"
            to="/sumate"
          >
            Contactanos
          </Link>
        </section>
        <SocialMedia />
        <Footer />
      </main>
    </div>
  );
}
