import React from 'react';
import Logoimg from './assets/Logo_LittlelineUp.png';
import { Link } from "react-router-dom";

export default function LogoLittle() {
  return (
    <Link to="/LineUpLittle">
      <div className="logo">
        <img id="logo" src={Logoimg} alt="Logolittle" />
      </div>
    </Link>
  );
}
