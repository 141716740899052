import React from 'react';
import imgWhite from '../../../../assets/line-up-white.svg';
import { Link } from "react-router-dom";

export default function LogoWhite() {
  return (
    <Link to="/">
      <div className="logo">
      <img id="logo" src={imgWhite} alt="Logo" />
    </div>
    </Link>
  );
}