import React from 'react';
import terceracita from '../../assets/terceracita.png';
import Video from '../../assets/proceso-video.jpg';
import { useEffect } from 'react';

export const ThirdCard = () => {

    useEffect(() => {
        document.getElementById('proceso').style.background = "#B4D8D0";
    }, []);

    return (
        <>
            <img
                className="proceso__renderimg"
                src={terceracita}
                alt="volver"
                style={{ width: '50%' }}
            />
            <div className="proceso__texto">
                <p className="proceso__caption">El kit Line Up</p>
                <h1 className="proceso__title">Tercera cita</h1>
                <p>Finalmente se envía los alineadores para que el ortodoncista los instale en la boca del paciente. Además, Agustina muestra el kit Line Up que recibe la persona para poder continuar con el tratamiento, y la plataforma digital para que se pueda realizar el seguimiento online.</p>
                <img
                    className="proceso__video"
                    src={Video}
                    alt="video"
                />
            </div>
        </>
    )
}
