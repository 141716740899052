import React, { useState } from 'react';
import Logo from '../ui/Header/Logo/Logo';
import { Firstcard } from './Firstcard';
import { SecondCard } from './SecondCard';
import { ThirdCard } from './ThirdCard';

export default function ConoceElProceso() {

  const [selected, setSelected] = useState('first');

  return (
    <div className="container-proceso">
      <div className="proceso" id="proceso">
        <div className="proceso__logo">
          <Logo />
        </div>

        <div className="proceso__card">
          {
            selected === 'first' &&
            <Firstcard />
          }
          {
            selected === 'second' &&
            <SecondCard />
          }
          {
            selected === 'third' &&
            <ThirdCard />
          }
        </div>

        <div className="proceso__nav">
          <div className={selected === 'first' ? 'proceso__dots--filled' : 'proceso__dots'} onClick={() => setSelected('first')}></div>
          <div className={selected === 'second' ? 'proceso__dots--filled' : 'proceso__dots'} onClick={() => setSelected('second')}></div>
          <div className={selected === 'third' ? 'proceso__dots--filled' : 'proceso__dots'} onClick={() => setSelected('third')}></div>
        </div>
      </div>
    </div>
  )
}
