import React from 'react';
import { Link } from 'react-router-dom';
import Hands from '../../assets/hands.jpg';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';


const TestSectionStyled = styled.div`
  overflow: hidden;

  .stickyTest, .sticky, .sticky2  {   
    height: 100vh;    
    background: #C3B7AB;
    width: 100%;

    .stickyImg {
      width: 100%;
      position: inherit;
      @media (max-width:800px ){
        position: relative;
      }
      left:10%;
      img{
        padding: 3rem;
        padding-left: 10%;
        @media (max-width:800px ){
          padding-left: 0%;
        }
      }
    }
    .heading {
      position: absolute;
      height: 100%;
      width: 100%;
      span {
        position: absolute;
        bottom: 0%;
        left: 100%;
        margin: 0;
      }
    }
  }
`;

const TestSectionCelu = () => (
  <TestSectionStyled>
    <Controller>    
          <div className="stickyTest">            
            <div className="stickyImg">
              <img
                className="home__test-hands"
                src={Hands}
                alt="Hands"
              />
            </div>
                <div className="home__test-information">
                  <div className="espacio-vacio"></div>
                  <div className="home__test-title">
                    Line Up se adapta a tu vida, por eso vas a recibir un kit para continuar con tu día sin problemas.
                  </div>

                  <div className="home__test-content">
                    El kit es un bolso pequeño transportable que contiene un cepillo de dientes, chiwis, quita placas, el contenedor de los alineadores, hilo y pasta dental. Todo lo necesario para que se ajuste a tu rutina y lleves tu tratamiento a todas partes. ¿Querés descubrir si Line Up es para vos?
                  </div>

                  <Link className="button--violet home__button-test" to="/test">
                    Hacé el test
                  </Link>
                </div>   
          </div>
    </Controller> 
  </TestSectionStyled>
  
);

export const SectionTestCelu = () => {
  return (
    <div className="home__test-container home__test-container-celu ">
      <TestSectionCelu />
    </div>
    )
}
