import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from './Navbar/Navbar';

export default function Header() {
  return (
    <header className="header__container " id="header">
      {/* <Logo /> */}
      <Navbar />
      <Link to="/login" className="header__login">
        {/*  Log in */}
      </Link>
    </header>
  );
}
