import React from 'react';
import { useState } from 'react';
import { FirstScreen } from './FirstScreen';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';



export const StartTest = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);


    const [started, setStarted] = useState(false);

    const startTest = (e) => {
        e.preventDefault();

        setStarted(true);
    }



    return (
        <div className="test__container">
            <Helmet>
             <title>Line Up Argentina | ¿Puedo Usar Alineadores Invisibles?</title>
             <meta name="description" content="Respondé este breve test así encontremos el mejor tratamiento de ortodoncia para la sonrisa que buscás. Dejate sorprender por los alineadores Line Up." />
             <link rel="canonical" href="https://line-up.com.ar/test" />
            </Helmet>
            {
                !started &&
                <>
                    <h1 className="test__title">Busquemos los mejores alineadores<br/> para vos.</h1>
         
                    <p className="test__first-paragraph">
                        Vamos a hacerte una serie de preguntas para identificar mejor cuál es<br/>el estado de tu sonrisa.
                    </p>

                    {/* <div className="sumate__message-circle">
                        <img src={message} alt="Message" />
                    </div> */}

                    <button
                        onClick={startTest}
                        className="test__start-button"
                    >
                        Empezar
                    </button>
                </>
            }


            {
                started &&

                <FirstScreen />
            }
        </div>
    )
}
