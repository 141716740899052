


import React, { useEffect, useState } from 'react';

/* import { SectionAppointment } from './SectionAppointment';
import { SectionAppointmentMobile } from './SectionAppointmentMobile'; */

import { SectionTest } from './SectionTest';
import { SectionTestCelu } from './SectionTestCel';
import Alineador from '../../assets/clearcorrect.png';
import { Footer } from '../Footer/Footer';
import UsersReviewsSimple from './UserReview';
import { SocialMedia } from './SocialMedia';
/* import { Link } from 'react-router-dom'; */
import { Helmet } from 'react-helmet';
import whatsapp from '../ui/Header/Logo/assets/logo-wht.verde.png';
//import isnta from '/../ui/Header/Logo/insta.png';
import ReactGA from 'react-ga';


import { SectionExperience } from './SectionExperience';
import { Partners } from './Partners';
import { constants } from '../../constants/constants.js';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useRef } from 'react';
const API_PATH = 'https://line-up-be.herokuapp.com/v1/text-mail';

export default function HomeScreen() {
  const handleOpenLink = (url) => {
    window.open(url, '_blank');
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  useEffect(() => {
    document.getElementById('header').style.display = "";
  }, []);
  /* desde aca*/
  const [text, setText] = useState("Enviar");

  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMail, setErrorMail] = useState("");
  const [phone, setPhone] = useState("");
  const [province, setProvince] = useState("");
  const [comment, setComment] = useState("");
  const [paciente, setPaciente] = useState(false);
  const [especialista, setEspecialista] = useState(false);
  const [errorRol, setErrorRol] = useState("");
  const [error, setError] = useState("");

  const [wasSubmit, setwasSubmit] = useState(false);
  let history = useHistory();
  let btnRef = useRef();

  function handleFormSubmit(event) {
    event.preventDefault();
    setwasSubmit(true);
    setErrorName('');
    setErrorMail('');
    setErrorRol('');
    setError('');

    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


    if (name == "" || email == "" || !pattern.test(email) || (paciente == false && email == false)) {
      if (name == "") {
        setErrorName('El campo Nombre y Apellido es obligatorio');
      }
      if (email == "") {
        setErrorMail('El campo Email es obligatorio');
      }
      if (!pattern.test(email)) {
        setErrorMail('El Email debe ser válido');
      }
      if (paciente == false && email == false) {
        setErrorRol('Debes seleccionar paciente o especialista.');
      }

    } else {
      if (btnRef.current) {
        btnRef.current.setAttribute("disabled", "disabled");
      }
      setText('Enviando...');
      let rol = 'Especialista';
      if (paciente) {
        rol = 'Paciente'
      }
      /* 	console.log(email, name, comment); */
      let formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('province', province);
      formData.append('phone', phone);
      formData.append('rol', rol);
      formData.append('comment', comment);

      axios({
        method: 'post',
        url: `${API_PATH}`,
        withCredentials: true,
        crossdomain: true,
        headers: {
          'content-type': 'multipart/form-data'
        },
        data: formData
      })
        .then(result => {
          setText('Enviar');
          setErrorName('');
          setErrorMail('');
          setErrorRol('');
          setError('');
          setName('');
          setEmail('');
          setPhone('');
          setProvince('');
          setEspecialista(false);
          setPaciente(false);
          setComment('');
          if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "false");
          }
          history.push('/enviado')
        }).catch(error => history.push('/enviado'));
      /* .catch(error => setError(error.message)); */

    }
  };

  const handlePacienteChange = () => {
    setPaciente(true);
    setEspecialista(false);
    setErrorRol('');
  }
  const handleEspecialistaChange = () => {
    setEspecialista(true);
    setPaciente(false);
    setErrorRol('');
  }
  /** hasta aca */



  return (
    <main className="home__container">
      <Helmet>
        <title>Line Up Argentina | Ortodoncia Invisible Sin Brackets</title>
        <meta name="description" content="Line Up es una nueva tecnología para alinear tus dientes a través de placas transparentes. Disfrutá de tu vida con más beneficios y menos preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/" />
      </Helmet>
      {/*<section className='sec-wht'>
        <div className='div-wth'>
          <a className='link-wht' onClick={e => { e.preventDefault(); handleOpenLink(constants.whatsaappLink); }}>  <img src={whatsapp} className="icono_redes redes_inta img-wht" alt="instagram" /> <h2>Hacenos tu consulta</h2></a>
        </div>
      </section>*/}
      <section className="home__section home-section-width">

        <h2 className="home__title">
          Decilo con
          <br />
          una sonrisa
        </h2>

        <div className="home__box">
          <h1 className="home__title2">Alineadores invisibles</h1>
          <p className="home__text">
            Line Up es una nueva tecnología que alinea tus dientes, de una forma
            parecida a la ortodoncia tradicional, pero a través de placas
            transparentes que te permitirán seguir disfrutando
            de tu vida con más beneficios y menos preocupaciones.
          </p>
        </div>

        <img src={Alineador} className="alineador-img" alt="Alineador" />

      </section>

      <div className="section-exp" >
        <SectionExperience />
      </div>
      <SectionTest />

      <div className="sumate__container sumate_home">
        <div className="sumate-text-container">
          <p className="sumate__description2 sumate__description">
            Dejanos tus datos para poder asesorarte de la mejor manera
          </p>
        </div>

        <div className="sumate__form-container sumate_form_home">
          <form > {/* style={{ maxWidth: '650px' }} */}
            <input
              type="text"
              className="input"
              required={true}
              value={name}
              onChange={e => { setName(e.target.value); setErrorName(''); }}
              placeholder="Nombre y Apellido*"
            />
            <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorName}</div>
            <input
              type="text"
              className="input"
              required={true}
              value={email}
              onChange={e => { setEmail(e.target.value); setErrorMail(''); }}
              placeholder="Mail*"
            />
            <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorMail}</div>
            <input
              type="text"
              className="input"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              placeholder="Celular"
            />
            <input
              type="text"
              className="input"
              value={province}
              onChange={e => setProvince(e.target.value)}
              placeholder="Provincia"
            />
            <label style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>Sos *</label><br /><br />
            <label style={{ paddingLeft: '1rem', color: 'grey', fontSize: '14px' }}>
              <input
                type="radio"
                defaultChecked={paciente}
                checked={paciente}
                onClick={e => handlePacienteChange(e.target.value)}
              /> Paciente
            </label >
            <label style={{ paddingLeft: '1rem', color: 'grey', fontSize: '14px' }}>
              <input
                type="radio"
                defaultChecked={especialista}
                checked={especialista}
                onClick={e => handleEspecialistaChange(e.target.value)}
              /> Especialista
            </label>

            <label style={{ color: 'red', fontSize: '12px' }}><br /><span style={{ paddingLeft: '1rem', color: 'red' }}>{errorRol}</span></label>
            <br />
            <textarea
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Quiero recibir más información"
              className="input textarea"
            ></textarea>
            <label style={{ paddingLeft: '1rem', paddingBottom: '1rem', paddingTop: '1rem', color: 'red' }}>{error}</label>
            <button
              ref={btnRef}
              onClick={e => handleFormSubmit(e)}
              c className={`sumate__enviar ${text != "Enviando..." ? "" : "prohibido"}`}
              type="submit"
            >
              {text}
            </button>
          </form>
        </div>

        {/* <div className="sumate__message-circle">
                            <img src={message} alt="Message" />
                        </div> */}
      </div>
      <UsersReviewsSimple />
      {/*
      <SearchInMap /> */}
      <Partners />
      <Footer />
    </main>
  );

}
