import React, { useEffect, useState } from "react";
/* const {render} = require('react'); */
/* const {StoreLocator} = require('StoreLocator'); */
import { Link } from 'react-router-dom';
import { StoreLocator } from './StoreLocator';
import axios from 'axios';
import './StoreLocator.css';
import { SocialMedia } from '../HomeScreen/SocialMedia';
import Foto1 from '../../assets/encontra-foto1.jpg';
import FotoEsp1 from '../../assets/foto-especialista.jpg';
import { Footer } from '../Footer/Footer';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import StoresService from '../../services/storesService';

// const defaultProps = {
//   stores: [],
//   zoom: 16,
//   limit: 10,
//   center: {lat: -34.593998, lng: -58.401892},
//   travelMode: 'DRIVING',
//   homeLocationHint: 'Current location',
//   homeMarkerIcon: '../map/house.png',
//   storeMarkerIcon: '../map/storeIcon.png',
//   unitSystem: 'METRIC',
//   farAwayMarkerOpacity: 0.3,
//   fullWidthMap: false
// };

export default function EncontrarEspecialista() {

  const [dataStores, getStores] = useState([]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
/*
    const getData = async () => {      
      const resp = await StoresService.getUbicaciones("consultorios");
      getStores(resp);
      
      console.log("demtro del getData", resp);
    
       getStores((Object.entries(resp).map(
        ([key, value]) => ({ [key]: value })))
      )
    }
    getData();*/
  }, []);
  /*console.log("dataSto", {dataStores});*/
  const container = 'store-locator';
  const config = {
    container: 'my-store-locator',
    apiKey: 'AIzaSyAFvcZ3H6PLPI_0JJjiLKJ3b_h6iwmUZJE',
    markerIconSize: [30, 30],
    limit: 1,
    searchHint: "",
    homeLocationHint: 'Tu ubicación',
    stores:[{
      "name": "Lauría Luz Rebeca",
      "address": "calle 14 1277, La Plata - 2215670325",
      "location": {lat: -34.9262864, lng: -57.9505002},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{   
      "name": "Lauría Luz Rebeca",
      "address": "calle Roca 3383, Saladillo - 2215670325",
      "location": {lat: -31.4149608, lng: -64.505091},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Abodenky Gabriela Fernanda",
      "address": "Calle Araoz 2420, CABA - 1154020691",
      "location": {lat: -34.586476, lng: -58.4162786},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "alvarez alejandra",
      "address": "Calle Salguero 1921, Palermo - 01149398375",
      "location": {lat: -34.5823418, lng: -58.4101062},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Astoreca María Euguenia",
      "address": "Franklin 119, Chascomús - ",
      "location": {lat: -35.57358, lng: -58.0131736},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Barone Juan",
      "address": "Calle Avenida Córdoba 1886, Balvanera - 2216308622",
      "location": {lat: -34.5980173, lng: -58.4119275},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Bigres Leguizamon Macarena",
      "address": "Calle 108  10384, Magdalena - 2223435843",
      "location": {lat: -35.0805371, lng: -57.5177029},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Boiardi Antonella",
      "address": "Calle  530 1633, Tolosa -  2215732109",
      "location": {lat: -34.9028749, lng: -57.9757345},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Bonfanti  Graciela ",
      "address": "Calle 476 1896, City Bell - 2216215938",
      "location": {lat: -34.8724869, lng: -58.0414323},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Bonfanti  Graciela ",
      "address": "Calle 66 842, La Plata - 2216215938",
      "location": {lat: -35.0056751, lng: -58.0221922},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Cacciavillano María Fiorella",
      "address": "Calle 77 764, La Plata - 2345412685",
      "location": {lat: -34.9459538, lng: -57.9354151},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Campostrini Sofía",
      "address": "Gascón 670, Almagro - 1131503549",
      "location": {lat: -34.6034398, lng: -58.4242946},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Cano Magalí",
      "address": "Calle 66 1139, La Plata - 2215659220",
      "location": {lat: -34.93579862993708, lng: -57.94564962387086},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Castriciano Florencia",
      "address": "Alvarado 3336, Mar del Plata - 2234723164",
      "location": {lat: -38.0061334, lng: -57.5629193},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Corvalan Noelia",
      "address": "calle 4 1251, La Plata - ",
      "location": {lat: -34.9165785, lng: -57.940684},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Danovara Carolina",
      "address": "Calle 45 531, La Plata - 2214085498",
      "location": {lat: -34.9101777, lng: -57.9527085},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Demaria Verónica",
      "address": "Diagonal 74 25, La Plata - 2216 137218",
      "location": {lat: -34.8899448, lng: -57.956705},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Demarta María Belén",
      "address": "Calle 29 1486, La Plata - 2216760718  ",
      "location": {lat: -34.9436865, lng: -57.9610648},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Demarta María Belén",
      "address": "Calle Nicolás Videla 91, Quilmes - 1153770933",
      "location": {lat: -34.719639581531354, lng: -58.25624942779542},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Durand Julieta",
      "address": "Calle Virrey Arredondo 3545, Colegiales - 1149282711",
      "location": {lat: -34.5682006, lng: -58.4517897},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Esquivel Lorena",
      "address": "Calle General  Paz 1943, Posadas, Misiones - 3764348444",
      "location": {lat: -27.36708, lng: -55.89608},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Fernández Rocío",
      "address": "Calle 16 824, La Plata - 2215626572",
      "location": {lat: -34.9223774, lng: -57.960025},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Fernández Rocío",
      "address": "Viamonte 1644, CABA - 2215626572",
      "location": {lat: -34.6008035, lng: -58.3905439},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Gago Giuliana",
      "address": "Calle 40 644, La Plata - 2213507143",
      "location": {lat: -34.9081689, lng: -57.9605707},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Gago Giuliana",
      "address": "Calle Chile 317, Ezpeleta - 1162671348",
      "location": {lat: -34.7523361, lng: -58.2364288},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "garcia ghigliani daniela",
      "address": "Calle 4 1775, La Plata - 2215420110",
      "location": {lat: -34.9253323, lng: -57.9288851},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "garcia ghigliani daniela",
      "address": "Calle Hipólito Yrigoyen 39, San Vicente, Buenos Aires - 2215420110",
      "location": {lat: -35.025, lng: -58.42276},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle Virrey del Pino 2386, Belgrano - 2216213178",
      "location": {lat: -34.5684403, lng: -58.4565391},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Hawryszko Lucía",
      "address": "Calle 10 este 4179, Berisso - 2215941672",
      "location": {lat: -34.8716614, lng: -57.8857407},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Hawryszko Lucía",
      "address": "Calle Güemes 3820, Palermo, Buenos Aires - 1148330802",
      "location": {lat: -34.61315, lng: -58.37723},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Hawryszko Lucía",
      "address": "Calle 17 324, La Plata - 2215941672",
      "location": {lat: -34.9153269, lng: -57.9717203},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Herrlein Gabriela Andrea",
      "address": "Avenida Maipú 1343, Vicente López - 1136190923",
      "location": {lat: -34.525051, lng: -58.4828472},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Jones Lizzie Mariel",
      "address": "Calle 9 429, La Plata - 2215470505",
      "location": {lat: -34.9097456, lng: -57.9613017},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pascua Ancarola Adriana",
      "address": "Adolfo Alsina 1569, CABA - 1144221034",
      "location": {lat: -34.6112089, lng: -58.3884531},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Ramírez Natalia ",
      "address": "calle 8 n1187, La Plata - 2213640524",
      "location": {lat: -34.9192615399295, lng: -57.94605731964112},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Ruiz valeria",
      "address": "Diagonal 75 592, La Plata - 2215773042",
      "location": {lat: -34.9314585, lng: -57.948986},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Kriger Jorgelina",
      "address": "calle 37 965, La Plata - 2215931233",
      "location": {lat: -34.9115739, lng: -57.9708847},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Kriger Jorgelina",
      "address": "Avenida Pueyrredón 1157, caba - 1149638603",
      "location": {lat: -34.5970395, lng: -58.4031242},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Mazzuchi Larisa",
      "address": "calle 467 1752, City Bell - 2214 59-8857",
      "location": {lat: -34.87955892751541, lng: -58.05613517761231},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Miño Adriana",
      "address": "Diagonal 74 25, La Plata - 2216025935",
      "location": {lat: -34.8899448, lng: -57.956705},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Leger Laura ",
      "address": "calle 25 2841, Gonnet -  2216183950",
      "location": {lat: -34.89346406486654, lng: -58.01944255828858},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Leger Laura ",
      "address": "25 de Mayo 79, San Vicente, Buenos Aires - 2216183950",
      "location": {lat: -35.02405288055697, lng: -58.423377871513374},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Perego Victoria",
      "address": "Calle Mitre 45, Trenque Lauquen, Buenos Aires - 2392631610",
      "location": {lat: -35.97035, lng: -62.73432},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Novillo Leticia",
      "address": "Jerónimo Salguero 1854, CABA - 11 65685073",
      "location": {lat: -34.588567, lng: -58.4151783},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Martínez  Jorgelina",
      "address": "Calle 43 838 , La Plata - 2214354160",
      "location": {lat: -34.9142992, lng: -57.9611611},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Martínez  Jorgelina",
      "address": "26 n621, La Plata - 2215735977 ",
      "location": {lat: -34.928902716719854, lng: -57.974166870117195},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Obispo San Alberto 3027 , CABA - 01151611281",
      "location": {lat: -34.5831492, lng: -58.5075729},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle 47 923, La Plata - 2215374834",
      "location": {lat: -34.9190995, lng: -57.9588343},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle Callao 1016, CABA - 2216213178",
      "location": {lat: -34.6012202, lng: -58.3928377},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle Lavalle 239, Chascomús - 2216213178",
      "location": {lat: -35.5793475, lng: -58.0114922},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Martínez Quintana Milagros",
      "address": "Calle 19 311, City Bell - 2215679589",
      "location": {lat: -34.877622582530584, lng: -58.051071166992195},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "MASI JULIETA",
      "address": "Calle 476 1896, City Bell - 2215250362",
      "location": {lat: -34.86596834308401, lng: -58.03725242614747},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Vila Valentina",
      "address": "calle 64 606, La Plata - 2216381348",
      "location": {lat: -34.924561, lng: -57.9372042},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "sarasua noelia",
      "address": "calle 13 312, La Plata - 2216062451",
      "location": {lat: -34.9115900135217, lng: -57.96747207641602},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "García Ximena",
      "address": "Avenida Hipolito Yrigoyen 7785, Banfield - 01120704332",
      "location": {lat: -34.7459466, lng: -58.3996981},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "vergagni  maria elvira",
      "address": "Avenida Andrés Rolón 173, San Isidro - 1156400637",
      "location": {lat: -34.4817141, lng: -58.5278422},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "TABBIA SILVIA",
      "address": "Calle 20 1681, La Plata - 2215060165",
      "location": {lat: -34.9387565, lng: -57.9475027},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Salado Leandro ",
      "address": "Avenida Pueyrredón 1316, CABA - 3815442255",
      "location": {lat: -34.5955191, lng: -58.4029082},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Shule Veronica",
      "address": "Almirante Brown 262, San Isidro - 4742-1901",
      "location": {lat: -34.468998, lng: -58.5140877},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Paul Elizabeth",
      "address": "Obispo San Alberto 3027 , CABA - 01151611281",
      "location": {lat: -34.5831492, lng: -58.5075729},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Lauria Luz",
      "address": "Calle 14 1277, La Plata - 2215670325",
      "location": {lat: -34.9262864, lng: -57.9505002},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Lauria Luz",
      "address": "Roca 3383, Saladillo - 2215670325",
      "location": {lat: -35.6350373, lng: -59.7787458},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Zurita verzini María Belén",
      "address": "Diagonal 79 74, La Plata - 4215056",
      "location": {lat: -34.8906753, lng: -57.9566608},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Zurita verzini María Belén",
      "address": "Calle 15, Berazategui - 42564790",
      "location": {lat: -34.7617953, lng: -58.2076262},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "sigona nazarena",
      "address": "Pedriel 163, CABA - 1157027370",
      "location": {lat: -34.6347521, lng: -58.3881765},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Petrini  María Alejandra",
      "address": "Alemania 277, Junín, Buenos Aires - 2364553433",
      "location": {lat: -34.5970074, lng: -60.9369416},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Michelena Graciela",
      "address": "Calle 50 1386, La Plata - 2214511903",
      "location": {lat: -34.9295615, lng: -57.9657379},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Trias Patricia",
      "address": "Doctor Iraci  459, Armstrong - 0347 490169",
      "location": {lat: -32.7797945, lng: -61.6061219},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Tristan Mariana Agustina",
      "address": "Hipólito Yrigoyen 4525, Lanús - 1140881102",
      "location": {lat: -34.7055133, lng: -58.3916918},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pugliese Giselle",
      "address": "Sinclair 3168, Palermo - 1153429048",
      "location": {lat: -34.5734087, lng: -58.4227015},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pugliese Giselle",
      "address": "Avenida Coronel Diaz 2177, Palermo - 1151554288",
      "location": {lat: -34.5869731, lng: -58.40843},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pugliese Giselle",
      "address": "Calle del Caminante 30, Rincón de Milberg, Buenos Aires - 1125273197",
      "location": {lat: -34.61315, lng: -58.37723},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pugliese Giselle",
      "address": "Nazca 415, Flores, Buenos Aires -  46137183",
      "location": {lat: -34.63017993352936, lng: -58.470053672790534},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pugliese Giselle",
      "address": "O Higgins 345, San Isidro - 1138079418",
      "location": {lat: -34.47145, lng: -58.50776},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Ricci Daniela",
      "address": "Diagonal 76 307, La Plata - 2215962126",
      "location": {lat: -34.93143597669091, lng: -57.96811580657959},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Pirovani Milagros",
      "address": "Pinto 1050, Tandil - 02494420400",
      "location": {lat: -37.3248927, lng: -59.1293331},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Gil Mariana",
      "address": "Calle 47 923, La Plata - 2215374834   ",
      "location": {lat: -34.9190995, lng: -57.9588343},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Gil Mariana",
      "address": "Pueyrredon 1157, Barrio Norte, CABA - 1149638603",
      "location": {lat: -34.5970395, lng: -58.4031242},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Agara Mariana",
      "address": "Virrey del Pino 2386, Belgrano - 2216213178",
      "location": {lat: -34.5660209, lng: -58.4518786},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Agara Mariana",
      "address": "Callao 1016 , CABA - 2216213178",
      "location": {lat: -34.5969237, lng: -58.3935542},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Agara Mariana",
      "address": "Avenida San Martín 2665, Caseros, Buenos Aires - 2216213178",
      "location": {lat: -34.60071, lng: -58.56424},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Agara Mariana",
      "address": "Lavalle 239, Chascomús, Buenos Aires - 2216213178",
      "location": {lat: -35.5793475, lng: -58.0114922},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Suárez Neyive",
      "address": "Avenida Las Heras 2091, Palermo - 1164170478",
      "location": {lat: -27.474414, lng: -58.9980354},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Scaramuzzino Cecilia",
      "address": "Calle 36 1127, La Plata - 2216141180",
      "location": {lat: -34.9137063, lng: -57.9752428},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Venice Geanela",
      "address": "Roca 495, Bolívar - 2314 614016",
      "location": {lat: -36.2337978, lng: -61.1136229},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Martel Nancy Elizabeth",
      "address": "Tejedor 1993, Mar del Plata - 4795257",
      "location": {lat: -37.9577949, lng: -57.5471782},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "calle 43 838, La Plata - 2214354160",
      "location": {lat: -34.9142992, lng: -57.9611611},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "calle 26 621, La Plata - 2215735977 ",
      "location": {lat: -34.9289795, lng: -57.9743891},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle Pueyrredón 1157, Barrio Norte, CABA - 49638603",
      "location": {lat: -34.5970395, lng: -58.4031242},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": " ",
      "address": "Calle Avenida San Martín 2665, Caseros - 2216213178",
      "location": {lat: -33.3497209, lng: -61.4450563},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Restelli Dino Martín",
      "address": "Larrea 958, CABA - 2995062214",
      "location": {lat: -34.5968403, lng: -58.4018637},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Sarno Matías",
      "address": "Avenida Corrientes 4923, CABA - 2213030833",
      "location": {lat: -34.6009511, lng: -58.4351717},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Sánchez Ana",
      "address": "Pedriel 163, CABA - 01157027370",
      "location": {lat: -34.6347521, lng: -58.3881765},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Sánchez Ana",
      "address": "Calle 413 933, Berazategui - 01163522500",
      "location": {lat: -34.83333, lng: -58.16667},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Nacir Yael Nair",
      "address": "Avenida 13 312, La Plata - 2216062451",
      "location": {lat: -34.9114166, lng: -57.9678299},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Lobatti Sebastián",
      "address": "Avenida 13 413, La Plata - 2215911764",
      "location": {lat: -34.9132884, lng: -57.9657628},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Teixeira Amelia",
      "address": "Avenida 19 474, La Plata - 2215384052",
      "location": {lat: -34.919683, lng: -57.9704092},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Vizcay María Agustina",
      "address": "Calle 58 1174, La Plata - 2213044593",
      "location": {lat: -34.9299893, lng: -57.9554408},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Sardella María Laura",
      "address": "Avenida 66 1139, La Plata - 2215659220",
      "location": {lat: -34.9359199, lng: -57.9458845},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Vizcay María Agustina",
      "address": "Bynnon 1424, Adrogué - 2213044593",
      "location": {lat: -34.7947759, lng: -58.3836808},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Vizcay María Agustina",
      "address": "Lavalle 899, Rauch, Buenos Aires - 2213044593",
      "location": {lat: -36.7689006, lng: -59.0839881},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Real Emilia",
      "address": "La Rioja 1287, Mar del Plata - 2235397700",
      "location": {lat: -37.9938798, lng: -57.5482267},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Sarrailh María Victoria",
      "address": "Gorriti 476, Bahía Blanca - 2215426371",
      "location": {lat: -38.720951, lng: -62.2755148},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Vecchiato Daniela",
      "address": "Avenida Cipolletti 1021, Villa Regina, Río Negro - 2215520896",
      "location": {lat: -39.0915837, lng: -67.1160213},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "De Zan Patricio",
      "address": "Gascón 670, Almagro, CABA - 1131503549",
      "location": {lat: -34.6034398, lng: -58.4242946},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Diulio Nicolás",
      "address": "Calle 53 680, La Plata - 00214829467",
      "location": {lat: -34.91867212468192, lng: -57.950220108032234},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Luna Manrique",
      "address": "calle 58 867, La Plata - 2216495297",
      "location": {lat: -34.9242012, lng: -57.9493017},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Miranda María Dolores",
      "address": "Calle 6 585, La Plata - 2216228964",
      "location": {lat: -34.9094833, lng: -57.9547314},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Viola Mariela",
      "address": "Presidente Juan Domingo Perón 2781, Quilmes - 1123900216",
      "location": {lat: -34.7398859, lng: -58.2873924},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Acosta María Daiana",
      "address": "Simini 707, Trenque Lauquen, Buenos Aires - 2216247965",
      "location": {lat: -35.9777843, lng: -62.7402639},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Epele María Inés",
      "address": "Avenida 53 710, La Plata - 2216207208",
      "location": {lat: -34.9184146, lng: -57.9500168},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Epele María Inés",
      "address": "Hidalgo 335, Caballito, CABA - 01153398660     ",
      "location": {lat: -34.6154969, lng: -58.4391149},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Tempesta Rodrigo",
      "address": "Avenida Roca 936, Puerto Madryn - 02804456357",
      "location": {lat: -42.773346, lng: -65.0286755},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Tempesta Agustina",
      "address": "Gascón 670, Almagro, CABA - 01131503549",
      "location": {lat: -34.6034398, lng: -58.4242946},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Tempesta Agustina",
      "address": "Calle 17 840, La Plata - ",
      "location": {lat: -34.9234588, lng: -57.9608255},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "constant ana sofia ",
      "address": "Calle 60 1787, La Plata - 2215558576",
      "location": {lat: -34.94283467849392, lng: -57.965905666351325},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "KADJO SOFIA VICTORIA",
      "address": "calle 17 179, La Plata - 2215973512",
      "location": {lat: -34.9129751, lng: -57.9752015},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Cano Magali Claribel",
      "address": "Calle 66 1139, La Plata - 221 5659220",
      "location": {lat: -34.93592176597621, lng: -57.94533848762513},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Kiernan Maria Florencia",
      "address": "Calle 26 1214, La Plata -  2216218331",
      "location": {lat: -34.936558, lng: -57.9638975},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Marín Ochoa  Crisbel",
      "address": "Avenida Las Heras 3169, CABA - 1162085563",
      "location": {lat: -34.5870684, lng: -58.3966939},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Marín Ochoa  Crisbel",
      "address": "Calle Tucumán 1748, CABA - 1162085563",
      "location": {lat: -34.6005124, lng: -58.3693088},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  },{
      "name": "Borrazás Magdalena ",
      "address": "Calle 46 1095, La Plata - 0221156417447",
      "location": {lat: -34.921442, lng: -57.9631832},
      "photo": '/static/media/foto-especialista.11fa6b9d.jpg'
  }]  
  };
  return (
    <div>
      <Helmet>
        <title>Line Up Argentina | Encontrá tu Especialista Line Up</title>
        <meta name="description" content="¡Preguntale a tu ortodoncista por Line Up! Tenemos un experto en ortodoncia invisible cerca tuyo. Descubrí el tratamiento que revoluciona la ortodoncia." />
        <link rel="canonical" href="https://line-up.com.ar/encontrar-especialista" />
      </Helmet>

      <StoreLocator {...config} container={document.getElementById(container)} />
      <section>
        <div className="pregunta">
          <div className="pregunta__foto">
            <img src={Foto1} className="pregunta__fotofile" alt="Pregunta" />
          </div>
          <div className="pregunta__texto">
            <h1 className="map__title">Encontrá un especialista<br></br>en ortodoncia invisible</h1>
            <p>Los alineadores dentales son una nueva tecnología que propone una alternativa a la ortodoncia tradicional, por eso más especialistas deberían usarla y entender todos los beneficios que tiene, tanto para el paciente como para ellos mismos.</p>
          </div>
        </div>
      </section>
      <section className="especialistas__section formate pregunta__formate">
        <h2 className="formate__titulo pregunta__formatetitulo">Descubrí el tratamiento que revoluciona la ortodoncia.</h2>
        <p className="formate__texto pregunta__formatetexto">Trabajamos con especialistas en este campo que actualizaron su carrera profesional, y encontraron en Line Up, un espacio de capacitación constante y una nueva forma de llegar a sus pacientes.<br /><br />¿Te interesa seguir tu formación con nosotros?</p>
        <Link
          className="button--violet formate__button"
          to="/sumate"
        >
          Conocé más
        </Link>
      </section>
      <SocialMedia />
      <Footer />
    </div>
  );

};

/* console.log(config); */

/* export default class EncontrarEspecialista extends React.Component {
  render(...config, document.getElementById('my-store-locator')) {
    return <StoreLocator {...config} />;
  }
} */

/* export default function EncontrarEspecialista(){
  return (

    )
  } */


/* module.exports = function({container = 'store-locator', ...config}) {
  render(<StoreLocator {...config} />, document.getElementById(container));
}; */
