import React, { useRef, useEffect, useState } from 'react';

import gum from '../../assets/logos/gum.png';
import atmos2 from '../../assets/logos/atmos2.jpg';
import scheu from '../../assets/logos/scheu.svg';
import odontomix from '../../assets/logos/odontomix.png';
import cozy from '../../assets/logos/cozy.png';
import grimberg from '../../assets/logos/grimberg.png';
import socodonto from '../../assets/logos/socodonto.png';


import Carousel, { consts } from 'react-elastic-carousel';

export const Partners = () => {
  const [datos, setDatos] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const carouselRef = useRef(null);
  let resetTimeout;


  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  // Simula la carga asíncrona de datos
  useEffect(() => {
    // Tu lógica para cargar datos
    // Ejemplo: fetch('/api/datos').then(response => response.json()).then(data => setDatos(data));
    // Asegúrate de establecer totalPages también
  }, []); // El segundo parámetro es una dependencia vacía, por lo que este efecto se ejecutará solo después del montaje inicial

  useEffect(() => {
    // Este efecto se ejecutará cuando cambien los datos o totalPages
    if (datos && totalPages > 0) {
      // Ahora podemos acceder a carouselRef.current sin preocuparnos por null
      // Realiza operaciones con carouselRef aquí
      // ...

      // Por ejemplo, podrías usar un timeout para simular la espera antes de ejecutar goTo(0)
      resetTimeout = setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(0);
        }
      }, 1500);
    }

    // Limpia el timeout al desmontar el componente o cuando cambie la dependencia
    return () => {
      clearTimeout(resetTimeout);
    };
  }, [datos, totalPages]);


  return (
    <>
      <div className="home__container-more-info">
        <div className="home__title-more-info">
          Nuestros partners de la jornada
        </div>
        <Carousel ref={carouselRef} breakPoints={breakPoints} enableAutoPlay
          onNextEnd={({ index }) => {
            if (index + 1 === totalPages) {
              // No es necesario usar setTimeout aquí, ya que la lógica de espera se maneja en el segundo useEffect
              // carouselRef.current.goTo(0);
              setTotalPages(0); // Esto desencadenará el segundo useEffect para realizar la operación después de la espera
            }
          }}>
          <div className="fil-partners">
            <div className="col-partners"></div>
            <div className="col-partners"><img className="atmos__image" src={atmos2} alt="" /></div>
            <div className="col-partners"></div>
            <div className="col-partners"><img className="odontomix" src={odontomix} alt="" /></div>
            <div className="col-partners"></div>
          </div>
          <div className="fil-partners">
            <div className="col-partners"></div>
            <div className="col-partners"><img className="odontomix" src={grimberg} alt="" /></div>
            <div className="col-partners"></div>
            <div className="col-partners"><img className="odontomix" src={cozy} alt="" /></div>
            <div className="col-partners"></div>
          </div>
          <div className="fil-partners">
            <div className="col-partners"></div>
            <div className="col-partners"><img className="gum" src={gum} alt="" /></div>
            <div className="col-partners"></div>
            <div className="col-partners"><img className="socodonto" src={socodonto} alt="" /></div>
            <div className="col-partners"></div>
          </div>

        </Carousel>
      </div>
    </>
  )
}