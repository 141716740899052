import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';


export default function PreguntasFrecuentes() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return <div>
    
    <section className="faq">
    <Link to="/preguntas-pacientes" className="faq__der">
      <div className="faq__caption"><h2 className="faq__text">Pacientes</h2></div>
    </Link>

    <Link to="/preguntas-especialistas" className="faq__izq">
    <div className="faq__caption"><h2  className="faq__text">Especialistas</h2></div>
    </Link>
    </section>

  </div>;
}
