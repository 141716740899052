import React from 'react';
import { constants } from '../../constants/constants';


const handleOpenLink = (url) => {
    window.open(url, '_blank');
}

export const Footer = () => {
    return (
        <div className="footer__container">
            <ul>
                <li
                    onClick={e => { e.preventDefault(); handleOpenLink(constants.instagram); }}
                >
                    Instagram
                </li>
                <li
                    onClick={e => { e.preventDefault(); handleOpenLink(constants.facebook); }}
                >
                    Facebook
                </li>
                <li
                    onClick={e => { e.preventDefault(); handleOpenLink(constants.policy); }}
                >
                    Política de calidad
                </li>
            </ul>
            <div className="footer__copyright">

                ALL RIGHTS RESERVED © 2024 — Line Up
            </div>
        </div>
    )
}
