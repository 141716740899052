import React from 'react';
import Especialistas from '../../assets/porque-full.svg';
import Especialistas2 from '../../assets/porque-full2.jpg';
import Especialistas2m from '../../assets/porque-fullm.png';
import zac from '../../assets/porque2.jpg';
import women from '../../assets/porque3.jpg';

import Carousel, { consts } from 'react-elastic-carousel';
import { Footer } from '../Footer/Footer';
/* import teeth1 from '../../assets/teeth1.jpg';
import teeth2 from '../../assets/teeth2.jpg';
import move from '../../assets/move.svg';
import TwentyTwenty from 'react-twentytwenty'; */
import UsersReviews from '../HomeScreen/UserReview';

import { SocialMedia } from '../HomeScreen/SocialMedia';
import Intro from '../../assets/faq_pacientes.jpg';
import IntroMobile from '../../assets/faq_pacientes_mobile.jpg';
import soypaciente from '../../assets/soypaciente.JPG';
import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';
import Flecha from '../../assets/flecha.svg';
import { Link } from 'react-router-dom';

import Icon1 from '../../assets/slider_porque_icon1.svg';
import Icon2 from '../../assets/slider_porque_icon2.svg';
import Icon3 from '../../assets/slider_porque_icon3.svg';
import Icon4 from '../../assets/slider_porque_icon4.svg';
import Icon5 from '../../assets/slider_porque_icon5.svg';
import Icon6 from '../../assets/slider_porque_icon6.svg';
import Icon7 from '../../assets/slider_porque_icon7.svg';
import Icon8 from '../../assets/slider_porque_icon8.svg';
import Icon9 from '../../assets/slider_porque_icon9.svg';

import chicaHome from '../../assets/chicaHome.jpg';


import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';



export default function PorQue() {
  const data = [
    {
      title: '¿Cuánto dura el tratamiento?',
      paragraph: 'De acuerdo a la complejidad de tu caso puede durar de 6 a 18 meses. Lo determinaremos junto a tu especialista en la primera consulta.'
    },
    {
      title: '¿Cuánto sale el tratamiento?',
      paragraph: 'Todas las sonrisas son distintas, por eso el precio depende del tipo de tratamiento y los honorarios de los profesionales.'
    },
    {
      title: '¿Cómo obtengo los alineadores?',
      paragraph: 'Para recibir el kit Line Up con los alineadores tenés que visitar a un profesional. Ingresá en Encontrá tu Especialista, completá con tu ubicación y coordiná tu turno con el profesional. '
    },
    {
      title: '¿De qué están hechos?',
      paragraph: 'Los alineadores son láminas transparentes, flexibles y super finas, diseñadas y fabricadas exclusivamente para corregir la posición de tus dientes.'
    }

  ]


  class Accordion extends React.Component {
    render() {
      return (
        <div {...{ className: 'wrapper' }}>
          <ul {...{ className: 'accordion-list' }}>
            {data.map((data, key) => {
              return (
                <li {...{ className: 'accordion-list__item', key }}>
                  <AccordionItem {...data} />
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  class AccordionItem extends React.Component {
    state = {
      opened: false
    }

    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this

      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
  });

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3, itemsToScroll: 3, pagination: true },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 6 },
  ];
  return (


    <div>
      <Helmet>
        <title>Line Up Argentina | Nuestro Sistema de Alineadores Invisibles</title>
        <meta name="description" content="A través de placas transparentes, casi invisibles, tus dientes se alinearán sin dolor ni tratamientos complicados. Disfrutá de tu sonrisa sin preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/por-que-line-up" />
      </Helmet>
      <main>

        <div className="intro">
          <img className="intro__img" src={Intro} alt='Intro' />
          <img className="intro__img--mobile" src={IntroMobile} alt='Intro' />
          <div className="intro__content">
            <Link
              className="button--violeta parte__button intro__button"
              to="/preguntas-especialistas"
            >
              <div style={{ paddingRight: '15px' }}>Ver preguntas para especialistas </div> <div> <img className="button-icon-felcha" src={Flecha} alt='ver' /></div>
            </Link>
            <h1 className="intro__subtitulo">¿Por qué las placas transparentes Line Up<br></br>son para vos?</h1>
            <div className="intro__cta">
              <h2 className="intro__titulo">Lo esencial es<br /> invisible a<br /> los ojos</h2>
            </div>
            <p className="intro__text">Entendemos que Line Up es un tratamiento novedoso y que pueden surgir muchas dudas. Es muy importante para nosotros brindarte toda la información que necesitás para que puedas comenzar con esta nueva experiencia lo antes posible.</p>
          </div>
        </div>

        <div className="porque__container">
          <div className="porque__information">
            <div className="porque__information-question">¿Por qué usar Line Up?</div>

            <Carousel itemsToShow={5} itemsToScroll={5} enableMouseSwipe={true} breakPoints={breakPoints} renderArrow={myArrow}>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon1} alt="" /></div>
                <h3>Ya sabes el resultado</h3>
                <p>La tecnología posibilita ver los resultados antes de iniciar el tratamiento.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon2} alt="" /></div>
                <h3>Libertad de limpieza</h3>
                <p>Placas removibles que permiten un mejor lavado de los dientes en los momentos necesarios.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon3} alt="" /></div>
                <h3>No hay alambres</h3>
                <p>Material flexible que se adapta completamente a la superficie de la boca sin lastimar.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon4} alt="" /></div>
                <h3>Comé lo que quieras</h3>
                <p>Se pueden retirar las placas para disfrutar completamente de las comidas.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon5} alt="" /></div>
                <h3>Elegí tu sonrisa</h3>
                <p>Transparentes casi invisibles y removibles para elegir cuándo usarlos.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon6} alt="" /></div>
                <h3>Descansá tus músculos</h3>
                <p>También funcionan como férulas de descanso para el bruxismo.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon7} alt="" /></div>
                <h3>Seguí practicando</h3>
                <p>Gracias a su materialidad y flexibilidad, los alineadores se pueden usar en la mayoría de los deportes.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon8} alt="" /></div>
                <h3>Más tiempo para vos</h3>
                <p>El proceso de alineamiento es mucho más rápido que el resto de los tratamientos.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon9} alt="" /></div>
                <h3>Dientes más sanos</h3>
                <p>No daña la superficie del esmalte de los dientes.</p>
              </div>
            </Carousel>
          </div>
        </div>
        <div className={"home__container-more-info fondo-blanco"}>
          <div className="home__title-more-info">
            ¿Querés saber más?
          </div>
          <Link
            className="button--violeta formate__button"
            to="/sumate"
          >
            Contactanos
          </Link>
        </div>


        <div className="home__container-find-specialist" style={{ background: '#EFEFEF' }}>
          <div className="home__container-find-specialist-center">
            <h4 className="home__find-specialist-title">Encontrá un especialista cerca tuyo.</h4>
            <div className="home__find-specialist-detail">
              Contamos con la tecnología y el equipo de profesionales necesario para brindar la mejor calidad de producto posible y al mejor precio.
            </div>
            <Link to="/encontrar-especialista">
              {/*  <form>
          <div className="home__row-input home__row-input-alt">
            <div>
              <select>
                <option disabled selected> -- Seleccionar especialista </option>
                {
                  console.log(result.data.data.odontologos)                 
                }
              </select>

            </div>
            <div>
              <button
                className="home__search-button"
              >
                Buscar
              </button></div>
          </div>
        </form>*/}
            </Link>
          </div>
        </div>

        <Footer />
      </main>
    </div>
  );
}
