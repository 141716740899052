import React from 'react';

// Images
/* import img1 from '../../assets/images/1.png';
import img2 from '../../assets/images/2.png';
import img3 from '../../assets/images/3.png';
import img4 from '../../assets/images/4.png';
import img5 from '../../assets/images/5.png';
import img6 from '../../assets/images/6.png'; */
import img1 from '../../assets/ig_1.jpg';
import img2 from '../../assets/ig_2.jpg';
import img3 from '../../assets/ig_3.jpg';
import img4 from '../../assets/ig_4.jpg';

import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

/* import Feed from "react-instagram-authless-feed" */
/* 
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <div>
            <h1>Something went wrong.</h1>
            <p>
              N.B. VPN/NAT source ip addresses can trigger Instagram rate limits.
            </p>
          </div>
        );
      }
  
      return this.props.children;
    }
  } */

const your_token = "IGQVJWTVNWRlRvNFBBT2UwaUNZAX2pEX0ZArUFU5QlpTODZAxSEZA0eXNzYndfalJ5VXA0cWRQRko4aXkzdGV2azRIWEhzb3drMy15M1dlRk8wN056eWcxUHBKTDJCSExRMHZACMHZASWk5wR3h2YzVPaHc2TQZDZD";
export const SocialMedia = () => {
    return (
        <div className="home__social-media-container">
            <h4 className="home__social-media-title">Seguinos en nuestras redes</h4>
             {/*<InstagramFeed token={your_token}  counter="8"/>*}
{/*             <div className="home__cards-container">
                <a href="https://www.instagram.com/lineupalineadores/" rel="noopener" target="_blank"><img src={img1} alt="Instagram Line Up" /></a>
                <a href="https://www.instagram.com/lineupalineadores/" rel="noopener" target="_blank"><img src={img2} alt="Instagram Line Up" /></a>
                <a href="https://www.instagram.com/lineupalineadores/" rel="noopener" target="_blank"><img src={img3} alt="Instagram Line Up" /></a>
                <a href="https://www.instagram.com/lineupalineadores/" rel="noopener" target="_blank"><img src={img4} alt="Instagram Line Up" /></a>
            </div> */}
{/* 
                <img src={img1} alt='img1' />
                <img src={img2} alt='img2' />
                <img src={img3} alt='img3' />
                <img src={img4} alt='img4' />
                <img src={img5} alt='img5' />
                <img src={img6} alt='img6' /> */}
           {/*  <div className="home__cards-container"> */}
{/*             <ErrorBoundary> 
            <Feed userName="lineupalineadores" className="home__cards-container" classNameLoading="Loading" limit="6"/>
            </ErrorBoundary>  */}
            {/* </div> */}
        </div>
    )
}

/* 

.home__cards-container {
    display: flex;
    width: 100%;
    overflow: scroll;
    height: 420px;
}

.home__cards-container a {
  display: contents;
}

.home__cards-container img {
  padding: 10px;
  height: -webkit-fill-available;
} */