import React from 'react'
import { useContext } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';

export const LoginScreen = ({ history }) => {

  const { dispatch } = useContext(AuthContext);

  const handleLogin = () => {

    //conecto con base y valido usuario
    
    //si esta ok :
    dispatch({
      type: types.login,
      payload: {
        name: 'Nispot'
      }
    })
    history.replace('/');
  }


  return (
    <div>
      <button
        onClick={handleLogin}
      >
        Login
      </button>
    </div>
  )
}
