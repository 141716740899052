
import Especialistas from '../../assets/porque-full.svg';
import Especialistas2 from '../../assets/porque-full2.jpg';
import Especialistas2m from '../../assets/porque-fullm.png';
import zac from '../../assets/porque2.jpg';
import women from '../../assets/porque3.jpg';

import gum from '../../assets/logos/gum.png';
import atmos2 from '../../assets/logos/atmos2.jpg';
import scheu from '../../assets/logos/scheu.svg';
import odontomix from '../../assets/logos/odontomix.png';
import cozy from '../../assets/logos/cozy.png';
import grimberg from '../../assets/logos/grimberg.png';
import socodonto from '../../assets/logos/socodonto.png';
import '../../styles/components/LineUpPlus/_lineupplus.scss';
import Carousel, { consts } from 'react-elastic-carousel';
import { Footer } from '../Footer/Footer';
/* import teeth1 from '../../assets/teeth1.jpg';
import teeth2 from '../../assets/teeth2.jpg';
import move from '../../assets/move.svg';
import TwentyTwenty from 'react-twentytwenty'; */
import UsersReviews from '../HomeScreen/UserReview';

import { SocialMedia } from '../HomeScreen/SocialMedia';
import Intro from '../../assets/faq_pacientes.jpg';
import IntroMobile from '../../assets/faq_pacientes_mobile.jpg';
import Plus1 from '../../assets/Plus1.jpg';
import Plus2 from '../../assets/Plus2.jpg';
import BarraNaranja from '../../assets/BarraNaranja.jpg';
import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';
import Flecha from '../../assets/flecha.svg';
import { Link } from 'react-router-dom';

import React, { useRef, useEffect, useState } from 'react';
import Icon1 from '../../assets/slider_porque_icon1.svg';
import Icon2 from '../../assets/slider_porque_icon2.svg';
import Icon3 from '../../assets/slider_porque_icon3.svg';
import Icon4 from '../../assets/slider_porque_icon4.svg';
import Icon5 from '../../assets/slider_porque_icon5.svg';
import Icon6 from '../../assets/slider_porque_icon6.svg';
import Icon7 from '../../assets/slider_porque_icon7.svg';
import Icon8 from '../../assets/slider_porque_icon8.svg';
import Icon9 from '../../assets/slider_porque_icon9.svg';

import chicaHome from '../../assets/chicaHome.jpg';



import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';




export default function LineUpPlus() {
  const [datos, setDatos] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const carouselRef = useRef(null);
  let resetTimeout;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  const data = [
    {
      title: '¿Cuánto dura el tratamiento?',
      paragraph: 'De acuerdo a la complejidad de tu caso puede durar de 6 a 18 meses. Lo determinaremos junto a tu especialista en la primera consulta.'
    },
    {
      title: '¿Cuánto sale el tratamiento?',
      paragraph: 'Todas las sonrisas son distintas, por eso el precio depende del tipo de tratamiento y los honorarios de los profesionales.'
    },
    {
      title: '¿Cómo obtengo los alineadores?',
      paragraph: 'Para recibir el kit Line Up con los alineadores tenés que visitar a un profesional. Ingresá en Encontrá tu Especialista, completá con tu ubicación y coordiná tu turno con el profesional. '
    },
    {
      title: '¿De qué están hechos?',
      paragraph: 'Los alineadores son láminas transparentes, flexibles y super finas, diseñadas y fabricadas exclusivamente para corregir la posición de tus dientes.'
    }

  ]


  class Accordion extends React.Component {
    render() {
      return (
        <div {...{ className: 'wrapper' }}>
          <ul {...{ className: 'accordion-list' }}>
            {data.map((data, key) => {
              return (
                <li {...{ className: 'accordion-list__item', key }}>
                  <AccordionItem {...data} />
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  class AccordionItem extends React.Component {
    state = {
      opened: false
    }

    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this

      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    // Tu lógica para cargar datos
    // Ejemplo: fetch('/api/datos').then(response => response.json()).then(data => setDatos(data));
    // Asegúrate de establecer totalPages también
  }, []); // El segundo parámetro es una dependencia vacía, por lo que este efecto se ejecutará solo después del montaje inicial

  useEffect(() => {
    // Este efecto se ejecutará cuando cambien los datos o totalPages
    if (datos && totalPages > 0) {
      // Ahora podemos acceder a carouselRef.current sin preocuparnos por null
      // Realiza operaciones con carouselRef aquí
      // ...

      // Por ejemplo, podrías usar un timeout para simular la espera antes de ejecutar goTo(0)
      resetTimeout = setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(0);
        }
      }, 1500);
    }

    // Limpia el timeout al desmontar el componente o cuando cambie la dependencia
    return () => {
      clearTimeout(resetTimeout);
    };
  }, [datos, totalPages]);



  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }


  return (


    <div>
      <Helmet>
        <title>Line Up Argentina | Nuestro Sistema de Alineadores Invisibles</title>
        <meta name="description" content="A través de placas transparentes, casi invisibles, tus dientes se alinearán sin dolor ni tratamientos complicados. Disfrutá de tu sonrisa sin preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/por-que-line-up" />
      </Helmet>
      <main>

        <div className="intro_plus">
          <img className="plus1_image" src={Plus1} alt='Intro' />
        </div>

        <div className="intro">
          <div className="intro__content">
            <h2 className="plus1_title">¿Qué es <br></br>
              Line Up Plus +?</h2>
            <p className="plus1_text">Es un sistema desarrollado por el Dr Alfredo Alvarez para Line up,
              en el que la biomecánica de fuerzas y momentos se combina con la
              Ortodoncia transparente para lograr resultados efectivos y
              predecibles en el menor tiempo posible.</p>
          </div>
        </div>
        <br></br>
        <div className="plusback">
          <div class="dos-columnas">
            <div class="dos-columnas-container">
              <div class="columna-izq-container-50">
                <div className="test-home-desk">
                  <div className="plus2_title">
                    ¿Estás preparado
                    para el siguiente
                    paso de la
                    ortodoncia digital?
                  </div>
                </div>
              </div>
              <div class="columna-der-container-50">
                <div >
                  <img className="plus2_image" src={Plus2} alt="Hands" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div >
          <img src={BarraNaranja} alt="Hands" />
        </div>
        <div className="home__container-more-info">
          <div className="home__title-more-info">
            Nuestros partners de la jornada
          </div>
          <Carousel ref={carouselRef} breakPoints={breakPoints} enableAutoPlay
            onNextEnd={({ index }) => {
              clearTimeout(resetTimeout)
              try {
                if (index + 1 === totalPages) {
                  resetTimeout = setTimeout(() => {
                    carouselRef.current.goTo(0);
                  }, 1500); // mismo tiempo
                }
              } catch (error) {
                console.error('Error en el manejo del carrusel:', error);
                // Puedes agregar código adicional para manejar el error según tus necesidades
              }
            }}>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="atmos__image" src={atmos2} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={odontomix} alt="" /></div>
              <div className="col-partners"></div>
            </div>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={grimberg} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={cozy} alt="" /></div>
              <div className="col-partners"></div>
            </div>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="gum" src={gum} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="socodonto" src={socodonto} alt="" /></div>
              <div className="col-partners"></div>
            </div>

          </Carousel>
        </div>

        <Footer />
      </main>
    </div>
  );
}
