import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../ui/Header/Logo/Logo'
import { AuthContext } from '../../auth/AuthContext';
import axios from 'axios';
import { useState } from 'react';

export const Success = () => {

    /* const API_PATH = 'http://localhost:5000/v1/attachments-mail'; */
    //const API_PATH = 'https://line-up-be.herokuapp.com/v1/attachments-mail';
    //desarrollo
    /* const API_PATH_LOGIN = 'https://lineupapi.paulele.com.ar/api/auth/login'
     const API_PATH_CREARENCUESTA = 'https://lineupapi.paulele.com.ar/api/encuestas'
     const API_PATH_SUBIRIMAGEN = 'https://lineupapi.paulele.com.ar/api/encuestas/subirDocumento'
     const API_PATH_MAIL = 'https://lineupapi.paulele.com.ar/api/encuestas/enviarMail/'*/


    //Prodiccón
    const API_PATH_LOGIN = 'https://plataforma.line-up.com.ar/api/auth/login'
    const API_PATH_CREARENCUESTA = 'https://plataforma.line-up.com.ar/api/encuestas'
    const API_PATH_SUBIRIMAGEN = 'https://plataforma.line-up.com.ar/api/encuestas/subirDocumento'
    const API_PATH_MAIL = 'https://plataforma.line-up.com.ar/api/encuestas/enviarMail/'


    const { user } = useContext(AuthContext);
    console.log(user)

    const [error, setError] = useState("");



    useEffect(() => {
        let formDataLogin = new FormData();
        let data = {
            "email": "test@test.com",
            "password": "integrah"
        }
        //formDataLogin.append(data);


        let formData = {
            'nombre': user.userData.name,
            'email': user.userData.email,
            'cp': user.userData.cp,
            'telefono': user.userData.phone,
            'descripcion_dientes': user.teethDescription,
            'edad': user.ageRange,
            'uso_brackets': user.brackets,
            'rapidez_tratamiento': user.startTreatment,
            'conoce_lineup': user.adFrom
        }
        axios({
            method: 'post',
            url: `${API_PATH_CREARENCUESTA}`,
            withCredentials: true,
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        }).then(result => {
            enviarmail(result.data.data.id, result.data.token).then(result => {
                console.log(result)

            }).catch(error => setError(error.message));
            /*
            if (user.imageSelfie.length === 1) {
                console.log("entro aca 1")
                let formDataArchivo1 = new FormData();
                formDataArchivo1.append('jpg', user.imageSelfie[0]);
                formDataArchivo1.append('idEncuesta', result.data.data.id);
                formDataArchivo1.append('nombre', result.data.data.id + "-img1");
                let id = result.data.data.id
                axios({
                    method: 'post',
                    url: `${API_PATH_SUBIRIMAGEN}`,
                    withCredentials: true,
                    crossdomain: true,
                    headers: {
                        'Authorization': `Bearer ${result.data.token}`,
                        'Content-Type': 'application/json'
                    },
                    data: formDataArchivo1
                }).then(result => {
                    //falta el segundo archivo
                    if (user.imageSelfieGap.length === 1) {
                        console.log("entro aca 2")
                        let formDataArchivo2 = new FormData();
                        formDataArchivo2.append('jpg', user.user.imageSelfieGap[0]);
                        formDataArchivo2.append('idEncuesta', id);
                        formDataArchivo2.append('nombre', result.data.data.id + "-img2");
                        axios({
                            method: 'post',
                            url: `${API_PATH_SUBIRIMAGEN}`,
                            withCredentials: true,
                            crossdomain: true,
                            headers: {
                                'Authorization': `Bearer ${result.data.token}`,
                                'Content-Type': 'application/json'
                            },
                            data: formDataArchivo2
                        }).then(result => {
                            //mando mail solo con selfieGap
                            enviarmail(id, result.data.token).then(result => {
                                console.log(result)
                            }).catch(error => setError(error.message));
                        }).catch(error => setError(error.message));
                    } else {
                        //mando solo con la primera foto
                        enviarmail(id, result.data.token).then(result => {
                            console.log(result)
                        }).catch(error => setError(error.message));
                    }
                }).catch(error => setError(error.message));
            } else {
                //no tiene foto selfie controlo selfiegap
                if (user.imageSelfieGap.length === 1) {
                    console.log("entro a la 2")
                    let formDataArchivo3 = new FormData();
                    formDataArchivo3.append('jpg', user.user.imageSelfieGap[0]);
                    formDataArchivo3.append('idEncuesta', result.data.data.id);
                    formDataArchivo3.append('nombre', result.data.data.id + "-img2");
                    let id = result.data.data.id
                    axios({
                        method: 'post',
                        url: `${API_PATH_SUBIRIMAGEN}`,
                        withCredentials: true,
                        crossdomain: true,
                        headers: {
                            'Authorization': `Bearer ${result.data.token}`,
                            'Content-Type': 'application/json'
                        },
                        data: formDataArchivo3
                    }).then(result => {
                        //mando mail solo con selfieGap
                        enviarmail(id, result.data.token).then(result => {
                            console.log(result)
                        }).catch(error => setError(error.message));
                    }).catch(error => setError(error.message));
                } else {
                    //mando sin foto
                    enviarmail(result.data.data.id, result.data.token).then(result => {
                        console.log(result)
                    }).catch(error => setError(error.message));
                }
            }
        }).catch(error => setError(error.message));*/
        }).catch(error => setError(error.message));
    }, []);

    const enviarmail = (id, token) => {
        axios({
            method: 'post',
            url: `${API_PATH_MAIL}` + id,
            withCredentials: true,
            crossdomain: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(result => {
            return result
        }).catch(error => setError(error.message));
    }


    return (
        <div className="test__success-container">
            {<div className="test__logo">
                <Logo />
            </div>}
            <h3>¡Perfecto, recibimos tu información para que los expertos puedan evaluar mejor tu caso!</h3>

            <Link to="/" className="test__back-to-home">Volver a home</Link>
            <Link to="/por-que-line-up" className="test__tell-a-friend">Contale a un amigo</Link>
        </div>
    )
}
