import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer/Footer';

import { useEffect } from 'react';
import ReactGA from 'react-ga';



export const Success = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    return (
        <>
                    <div className="sumate__container">
                        <div className="sumate-text-container">
                            <h1 className="sumate__title">¡Gracias por contactarnos!</h1>

                            <p className="sumate__description">
                                Recibimos tu consulta con éxito y pronto nos pondremos en contacto con vos.
                            </p>
                        </div>
                        <Link
                            className="button--violet home__button-test"
                            to="/"
                        >
                            Volver al home
                        </Link>
                    </div>
            <Footer />
        </>
    )
}
