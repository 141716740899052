import React from 'react';
import { useEffect } from 'react';
import User1 from '../../assets/Line-Up_Review_Paciente_1.jpg';
import User2 from '../../assets/Line-Up_Review_Paciente_2.jpg';
import User3 from '../../assets/Line-Up_Review_Paciente_3.jpg';
import User4 from '../../assets/Line-Up_Review_Paciente_4.jpg';
import User5 from '../../assets/Line-Up_Review_Paciente_5.jpeg';
import User6 from '../../assets/Line-Up_Review_Paciente_6.jpeg';
import User7 from '../../assets/Line-Up_Review_Paciente_7.jpeg';
import User8 from '../../assets/Line-Up_Review_Paciente_8.jpeg';
import User9 from '../../assets/Line-Up_Review_Paciente_9.jpeg';
import Carousel, { consts } from 'react-elastic-carousel';
import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';

export default function UsersReviews() {

  useEffect(() => {
    let clase = `rec-carousel-item-0`;
    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");
  }, []);

  // const myArrow = ({ type, onClick, isEdge }) => {
  //   const pointer = type === consts.PREV ? PrevButton : NextButton
  //   return (
  //     <div onClick={onClick} disabled={isEdge}>
  //       <img className="nextCarrousel" src={pointer} alt="Next" />
  //     </div>
  //   )
  // }


  useEffect(() => {
    let clase = `rec-carousel-item-0`;
    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");
  }, []);



  // const myArrow = ({ type, onClick, isEdge }) => {
  //   const pointer = type === consts.PREV ? PrevButton : NextButton
  //   return (
  //     <div onClick={onClick} disabled={isEdge}>
  //       <img className="nextCarrousel" src={pointer} alt="Next" />  
  //     </div>
  //   )
  // }


  const setActiveItem = (item) => {

    let clase = `rec-carousel-item-${item.currentItem.index}`;

    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");

    if ((item.currentItem.index - 1) > -1) {
      let clasePrev = `rec-carousel-item-${item.currentItem.index - 1}`;
      let elPrev = document.querySelector(`div.slider_reviews div.${clasePrev}`);
      elPrev.classList.remove("slide-activo");
    }

    let claseNext = `rec-carousel-item-${item.currentItem.index + 1}`;
    let elNext = document.querySelector(`div.slider_reviews div.${claseNext}`);
    elNext.classList.remove("slide-activo");
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton;
    const clase = type === consts.PREV ? 'prev-custom' : 'next-custom';
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className={"nextCarrousel " + clase} src={pointer} alt="Next" />
      </div>
    )
  }

  return (
    <Carousel renderArrow={myArrow} style={{ marginTop: "3rem" }} className="slider_reviews" enableMouseSwipe={false} itemsToShow={4} pagination={false} onChange={(currentItem) => setActiveItem({ currentItem })}>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Arranqué mi tratamiento con alineadores Line Up y la verdad que está buenísimo, es práctico, cómodo y lo súper recomiendo. <br />
          </div>
          <div className="review__usuario">
            Julieta Volpe
          </div>
        </div>
        <div className="review__fotodiv">
          <img src={User1} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Hace 3 meses que tengo los alineadores, y estoy re contenta con el tratamiento. Me siento súper cómoda, ni se notan!.</div>
          <div className="review__usuario">
            Estefania Faduaga
          </div>
        </div>
        <div className="review__fotodiv">
          <img src={User2} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Estoy en mi sexta placa, llevo 3 meses y medio de tratamiento, y ha sido cómodo, fácil y ya estoy viendo muchos resultados.</div>
          <div className="review__usuario">Mercedes Juarez</div>
        </div>
        <div className="review__fotodiv">
          <img src={User3} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Hace 4 meses empecé el tratamiento, estoy por la segunda etapa, y mi sonrisa ya se está acomodando.</div>
          <div className="review__usuario">Julian Inghliterra</div>
        </div>
        <div className="review__fotodiv">
          <img src={User4} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Con el correr de los años noté que mis dientes se habían movido mucho. Quise buscar una solución ya que soy de sonreír ampliamente y no se veían lindos. Me propusieron Alineadores y su proyección me gustó. Ahora estoy muy contenta. Ve fotos de hace un año y no puedo creer lo que cambió no solo los dientes sino la cara también.</div>
          <div className="review__usuario">Ines Di Masi</div>
        </div>
        <div className="review__fotodiv">
          <img src={User5} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Desde que empecé a usar alineadores en poco tiempo observé grandes cambios en mi sonrisa  y también me ayudó a mantener una correcta salud bucal. Los super recomiendo!</div>
          <div className="review__usuario">Marisa Apestegui</div>
        </div>
        <div className="review__fotodiv">
          <img src={User6} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Los uso para ir al cole, a baile, a tenis y para dormir. Solo me los saco para comer y cepillarme dientes.</div>
          <div className="review__usuario">Julia</div>
        </div>
        <div className="review__fotodiv">
          <img src={User7} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Line up y Magui no solo me cambiaron la sonrisa, sino que generaron ese espacio de contención y confianza que uno necesita para poder animarse a un cambio tan grande
            como el de verse y sentirse mejor. Gracias por estos 2 años de amor.
          </div>
          <div className="review__usuario">Antonella Diaz</div>
        </div>
        <div className="review__fotodiv">
          <img src={User8} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Mi experiencia con los alineadores ha sido súper positiva. Lo que más valoro es que son prácticamente invisibles y muy efectivos. Son realmente cómodos, no generan molestias y solo me los saco para comer. Además, el tratamiento es mucho menos doloroso en comparación con los brackets tradicionales. Estoy encantada con los resultados y la comodidad que ofrecen.
          </div>
          <div className="review__usuario">Maria Sol Palermo</div>
        </div>
        <div className="review__fotodiv">
          <img src={User9} className="review__foto" alt="" />
        </div>
      </div>
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>

      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
    </Carousel>
  );
}