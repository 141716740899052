import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import EncontrarEspecialista from '../components/EncontrarEspecialista/EncontrarEspecialista';

// Components
import HomeScreen from '../components/HomeScreen/HomeScreen';
import NuestrosEspecialistas from '../components/NuestrosEspecialistas/NuestrosEspecialistas';
import PorQue from '../components/PorQue/PorQue';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/PreguntasFrecuentes';
import Pacientes from '../components/PreguntasFrecuentes/Pacientes';
import Especialistas from '../components/PreguntasFrecuentes/Especialistas';
import SoyOrtodoncista from '../components/SoyOrtodoncista/SoyOrtodoncista';
import SoyPaciente from '../components/SoyPaciente/SoyPaciente';
import LineUpPlus from '../components/LineUpPlus/LineUpPlus';
import LineUpLittle from '../components/LineUpLittle/LineUpLittle';
import Policy from '../components/Policy/Policy';
import Header from '../components/ui/Header/Header';
import ConoceElProceso from '../components/ConoceElProceso/ConoceElProceso';
import { Sumate } from '../components/Sumate/Sumate';
import { Success } from '../components/Sumate/Success';
import { StartTest } from '../components/ComenzarTest/StartTest';
import { LoginScreen } from '../components/Login/LoginScreen';


export default function AppRouter() {

  return (
    <Router>
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route path="/por-que-line-up" component={PorQue} />
          <Route
            path="/nuestros-especialistas"
            component={NuestrosEspecialistas}
          />
          <Route path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
          <Route path="/preguntas-pacientes" component={Pacientes} />
          <Route path="/preguntas-especialistas" component={Especialistas} />
          <Route
            path="/encontrar-especialista"
            component={EncontrarEspecialista}
          />
          <Route
            path="/proceso"
            component={ConoceElProceso}
          />
          <Route
            path="/soyOrtodoncista"
            component={SoyOrtodoncista}
          />
          <Route
            path="/soyPaciente"
            component={SoyPaciente}
          />
          <Route
            path="/LineUpPlus"
            component={LineUpPlus}
          />
          <Route
            path="/LineUpLittle"
            component={LineUpLittle}
          />
          <Route
            path="/Policy"
            component={Policy}
          />
          <Route
            path="/sumate"
            component={Sumate}
          />
          <Route
            path="/enviado"
            component={Success}
          />
          <Route
            path="/test"
            component={StartTest}
          />
          <Route
            path="/auth/login"
            component={LoginScreen}
          />
        </Switch>
      </div>
    </Router>

  );
}
