import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
const API_PATH = 'https://line-up-be.herokuapp.com/v1/text-mail';
/* const API_PATH = 'http://localhost:5000/v1/text-mail'; */
const API_PATH_MAIL = 'https://plataforma.line-up.com.ar/api/encuestas/mailContacto'


export const Sumate = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);


    const [text, setText] = useState("Enviar");

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState("");
    const [email, setEmail] = useState("");
    const [errorMail, setErrorMail] = useState("");
    const [phone, setPhone] = useState("");
    const [province, setProvince] = useState("");
    const [comment, setComment] = useState("");
    const [paciente, setPaciente] = useState(false);
    const [especialista, setEspecialista] = useState(false);
    const [errorRol, setErrorRol] = useState("");
    const [error, setError] = useState("");

    const [wasSubmit, setwasSubmit] = useState(false);
    let history = useHistory();
    let btnRef = useRef();

    function handleFormSubmit(event) {
        event.preventDefault();
        setwasSubmit(true);
        setErrorName('');
        setErrorMail('');
        setErrorRol('');
        setError('');

        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


        if (name == "" || email == "" || !pattern.test(email) || (paciente == false && email == false)) {
            if (name == "") {
                setErrorName('El campo Nombre y Apellido es obligatorio');
            }
            if (email == "") {
                setErrorMail('El campo Email es obligatorio');
            }
            if (!pattern.test(email)) {
                setErrorMail('El Email debe ser válido');
            }
            if (paciente == false && email == false) {
                setErrorRol('Debes seleccionar paciente o especialista.');
            }

        } else {
            if (btnRef.current) {
                btnRef.current.setAttribute("disabled", "disabled");
            }
            setText('Enviando...');
            let rol = 'Especialista';
            if (paciente) {
                rol = 'Paciente'
            }
            /* 	console.log(email, name, comment); */
            let formData = {
                'nombre': name,
                'email': email,
                'provincia': province,
                'celular': phone,
                'rol': rol,
                'texto': comment
            }
            axios({
                method: 'post',
                url: `${API_PATH_MAIL}`,
                withCredentials: false,
                crossdomain: true,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: formData
            })
                .then(result => {
                    setText('Enviar');
                    setErrorName('');
                    setErrorMail('');
                    setErrorRol('');
                    setError('');
                    setName('');
                    setEmail('');
                    setPhone('');
                    setProvince('');
                    setEspecialista(false);
                    setPaciente(false);
                    setComment('');
                    if (btnRef.current) {
                        btnRef.current.setAttribute("disabled", "false");
                    }
                    history.push('/enviado')
                }).catch(error => history.push('/enviado'));
            /* .catch(error => setError(error.message)); */

        }
    };

    const handlePacienteChange = () => {
        setPaciente(true);
        setEspecialista(false);
        setErrorRol('');
    }
    const handleEspecialistaChange = () => {
        setEspecialista(true);
        setPaciente(false);
        setErrorRol('');
    }

    return (
        <>
            <div className="sumate__container">
                <Helmet>
                    <title>Line Up Argentina | Consultanos sobre Nuestros Alineadores</title>
                    <meta name="description" content="Queremos que nuestros especialistas y pacientes se sientan únicos y formen parte de una gran comunidad. Contactanos para asesorarte de la mejor manera." />
                    <link rel="canonical" href="https://line-up.com.ar/sumate" />
                </Helmet>

                <div className="sumate-text-container">
                    <h1 className="sumate__title">Sé parte de la revolución en la ortodoncia sin brackets</h1>
                    <h2 className="sumate__description ">Buscamos que nuestros especialistas y pacientes sientan que son únicos y que forman parte de una gran comunidad.</h2>

                    <p className="sumate__description2 sumate__description">
                        Dejanos tus datos para poder asesorarte de la mejor manera
                    </p>
                </div>

                <div className="sumate__form-container">
                    <form style={{ maxWidth: '650px' }}>
                        <input
                            type="text"
                            className="input"
                            required={true}
                            value={name}
                            onChange={e => { setName(e.target.value); setErrorName(''); }}
                            placeholder="Nombre y Apellido*"
                        />
                        <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorName}</div>
                        <input
                            type="text"
                            className="input"
                            required={true}
                            value={email}
                            onChange={e => { setEmail(e.target.value); setErrorMail(''); }}
                            placeholder="Mail*"
                        />
                        <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorMail}</div>
                        <input
                            type="text"
                            className="input"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            placeholder="Celular"
                        />
                        <input
                            type="text"
                            className="input"
                            value={province}
                            onChange={e => setProvince(e.target.value)}
                            placeholder="Provincia"
                        />
                        <label style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>¿Querés ser paciente o especialista? *</label><br /><br />
                        <label style={{ paddingLeft: '1rem', color: 'grey', fontSize: '14px' }}>
                            <input
                                type="radio"
                                defaultChecked={paciente}
                                checked={paciente}
                                onClick={e => handlePacienteChange(e.target.value)}
                            /> Paciente
                        </label >
                        <label style={{ paddingLeft: '1rem', color: 'grey', fontSize: '14px' }}>
                            <input
                                type="radio"
                                defaultChecked={especialista}
                                checked={especialista}
                                onClick={e => handleEspecialistaChange(e.target.value)}
                            /> Especialista
                        </label>

                        <label style={{ color: 'red', fontSize: '12px' }}><br /><span style={{ paddingLeft: '1rem', color: 'red' }}>{errorRol}</span></label>
                        <br />
                        <textarea
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            placeholder="Quiero recibir más información"
                            className="input textarea"
                        ></textarea>
                        <label style={{ paddingLeft: '1rem', paddingBottom: '1rem', paddingTop: '1rem', color: 'red' }}>{error}</label>
                        <button
                            ref={btnRef}
                            onClick={e => handleFormSubmit(e)}
                            c className={`sumate__enviar ${text != "Enviando..." ? "" : "prohibido"}`}
                            type="submit"
                        >
                            {text}
                        </button>
                    </form>
                </div>

                {/* <div className="sumate__message-circle">
                            <img src={message} alt="Message" />
                        </div> */}
            </div>
            <Footer />
        </>
    )
}
