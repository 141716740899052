import React from 'react';
import { Link } from 'react-router-dom';
import webImage1 from '../../assets/NuevoPack1.JPG'
import webImage2 from '../../assets/NuevoPack2.JPG'
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';


const TestSectionStyled = styled.div`
  overflow: hidden;

  .stickyTest, .sticky, .sticky2  {     
    margin: 0 auto;    
    max-width: 1280px;

    .stickyImg {
      width: 100%;
      position: inherit;
      @media (max-width:800px ){
        position: relative;
      }
      left:10%;
      img{
        padding: 3rem;
        padding-left: 10%;
        @media (max-width:800px ){
          padding-left: 0%;
        }
      }
    }
    .heading {
      position: absolute;
      height: 100%;
      width: 100%;
      span {
        position: absolute;
        bottom: 0%;
        left: 100%;
        margin: 0;
      }
    }
  }
`;

const TestSection = () => (
  <TestSectionStyled>

    {/*       <div className="section" /> */}
    <div className="stickyTest">
      <div class="dos-columnas">
        <div class="dos-columnas-container">
          <div class="columna-izq-container-40">
            <div className="test-home-desk">
              <div className="test-title">
                Line Up se adapta a tu vida, por eso vas a recibir un kit para continuar con tu día sin problemas.
              </div>
              <div className="test-content">
                El kit es un bolso pequeño transportable que contiene un cepillo de dientes, quita placas, el contenedor de los alineadores, hilo y pasta dental. Todo lo necesario para que se ajuste a tu rutina y lleves tu tratamiento a todas partes. ¿Querés descubrir si Line Up es para vos?
              </div>
              <Link className="button--violet home__button-test" to="/test"> Hacé el test </Link>
            </div>
          </div>
          <div class="columna-der-container-60">
            <div className="test-foto">
              <img src={webImage1} alt="Hands" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*       <div className="section" /> */}
  </TestSectionStyled>
);

export const SectionTest = () => {
  return (
    <div className="home__test-container home__test-container-desk">
      <TestSection />
    </div>
  )
}
