import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/PreguntasFrecuentes/_faq.scss';
import Intro from '../../assets/faq_pacientes.jpg';
import IntroMobile from '../../assets/faq_pacientes_mobile.jpg';
import Flecha from '../../assets/flecha.svg';

import { SearchInMap } from '../HomeScreen/SearchInMap';
import { SocialMedia } from '../HomeScreen/SocialMedia';
import { Footer } from '../Footer/Footer';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// const paragraph = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet natus sint provident vel ab reprehenderit cum soluta, suscipit facere nisi sed earum repellendus fuga debitis, nam molestiae minima voluptates possimus.'

const data = [
    {
      title: '¿Cuánto dura el tratamiento?',
      paragraph: 'De acuerdo a la complejidad de tu caso puede durar de 6 a 18 meses. Lo determinaremos junto a tu especialista en la primera consulta.'
    },
    {
      title: '¿Cuánto sale el tratamiento?',
      paragraph: 'Todas las sonrisas son distintas, por eso el precio depende del tipo de tratamiento y los honorarios de los profesionales.'
    },
    {
      title: '¿Cómo obtengo los alineadores?',
      paragraph: 'Para recibir el kit Line Up con los alineadores tenés que visitar a un profesional. Ingresá en Encontrá tu Especialista, completá con tu ubicación y coordiná tu turno con el profesional. '
    },
    {
      title: '¿De qué están hechos?',
      paragraph: 'Los alineadores son láminas transparentes, flexibles y super finas, diseñadas y fabricadas exclusivamente para corregir la posición de tus dientes.'
    }

  ]
  

class Accordion extends React.Component {
  render () {
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }
  
  render () {
    const {
      props: {
        paragraph,
        title
      },
      state: {
        opened
      }
    } = this
    
    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
      </div>
    )
  }
}

export default function Especialistas() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return <div>
    <Helmet>
        <title>Line Up Argentina | ¡Quiero Mis Placas Transparentes!</title>
        <meta name="description" content="¿Querés lucir una sonrisa radiante, sin brackets, con un tratamiento rápido,  transparente y sin complicaciones? Aquí te respondemos todas tus consultas." />
        <link rel="canonical" href="https://line-up.com.ar/preguntas-pacientes" />
      </Helmet>
    <div className="intro">
        <img className="intro__img" src={Intro} alt='Intro' />
        <img className="intro__img--mobile" src={IntroMobile} alt='Intro' />
        <div className="intro__content">       
        <Link
                className="button--violet parte__button intro__button mobile"
                to="/preguntas-especialistas"
                >
                <div style={{paddingRight: '15px'}}>Ver preguntas para especialistas </div> <div> <img className="button-icon-felcha" src={Flecha} alt='ver' /></div>
                </Link> 
            <h1 className="intro__subtitulo">¿Por qué las placas transparentes Line Up<br></br>son para vos?</h1>
            <div className="intro__cta">
                <h2 className="intro__titulo">Lo esencial es<br/> invisible a<br/> los ojos</h2>
                <div className="enlace-otras-faq"> 
                <Link
                className="button--violet parte__button intro__button desktop"
                to="/preguntas-especialistas"
                >
                <div style={{paddingRight: '15px'}}>Ver preguntas para especialistas </div> <div> <img className="button-icon-felcha" src={Flecha} alt='ver' /></div>
                </Link>
                </div>
            </div>
            <p  className="intro__text">Entendemos que Line Up es un tratamiento novedoso y que pueden surgir muchas dudas. Es muy importante para nosotros brindarte toda la información que necesitás para que puedas comenzar con esta nueva experiencia lo antes posible.</p>
            <Accordion />
        </div>
    </div>

    <SearchInMap  fondoBlanco={true}/>
        <SocialMedia />
        <Footer />
  </div>;
}
