import React from 'react';

  export default function SearchIcon() {
    return (
  <svg
    className="SearchIcon"
    xmlns="http://www.w3.org/2000/svg"
    version="1.2"
    baseProfile="tiny"
    width="23.888"
    height="16px"
    viewBox="0 0 23.888 24.117">
    <g
      fill="none"
      stroke="#5937CD"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10">
      <circle cx="10.12" cy="10.12" r="8.12" />
      <path d="M15.849 16.077l6.039 6.04" />
    </g>
  </svg>
)
};
