import { useEffect, useState } from 'react';

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0, xspot: 0, xspotf: 0, yspot: 0, dif: 0 });

  useEffect(() => {
    const spotlight = document.querySelector('.spotlight');
    const spoty = spotlight.getBoundingClientRect().top;
    const spotx = spotlight.getBoundingClientRect().left;
    const spotxf = spotlight.getBoundingClientRect().right;

    const dif = (window.innerWidth - (spotxf - spotx)) / 2;


    const setFromEvent = e => setPosition({ x: e.clientX - dif, y: e.clientY, xspot: spotx, xspotf: spotxf, yspot: spoty, dif: dif });

    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return position;
};