


export const constants = {
    instagram: 'https://www.instagram.com/lineupalineadores/',
    facebook: 'https://www.facebook.com/lineup.alineadores',
    linkedin: 'https://www.linkedin.com',
    policy: 'http://line-up.com.ar/Policy',
    whatsaappLink: 'https://wa.me/+5492213550327/',

}
