import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Render from '../../assets/render.png';
import Back from '../../assets/back.svg';
import Video from '../../assets/proceso-video.jpg';

export const Firstcard = () => {
    useEffect(() => {
        document.getElementById('proceso').style.background = "#D8D1FD";
        document.getElementById('header').style.display = "none";
    }, []);
    return (
        <>
            <div className="proceso__render">
                <div className="proceso__back">
                    <Link
                        to="/"
                    >
                        <img
                            className="proceso__backbutton"
                            src={Back}
                            alt="volver"
                        />
                    </Link>
                    <img
                        className="proceso__renderimg"
                        src={Render}
                        alt="volver"
                    />
                </div>
            </div>
            <div className="proceso__texto">
                <p className="proceso__caption">Conocé el proceso para usar alineadores invisibles</p>
                <h1 className="proceso__title">Primera cita</h1>
                <p>Antes de comenzar a usar los alineadores, los ortodoncistas y Line Up deben conocer cada detalle de la boca de los pacientes. Agustina cuenta un poco el tratamiento, sus beneficios y los estudios que se realizan, junto a un especialista, para entender si Line Up es la solución para el paciente.</p>
                <img
                    className="proceso__video"
                    src={Video}
                    alt="video"
                />
            </div>
        </>
    )
}
