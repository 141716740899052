import React from 'react';
import { useEffect } from 'react';
import User1 from '../../assets/especialistas_testimonios1.jpg';
import User2 from '../../assets/especialistas_testimonios2.jpg';
import User3 from '../../assets/especialistas_testimonios3.jpg';
import User4 from '../../assets/especialistas_testimonios4.jpg';
import User5 from '../../assets/especialistas_testimonios5.jpg';
import User6 from '../../assets/especialistas_testimonios6.jpeg';
import User7 from '../../assets/especialistas_testimonios7.jpeg';
import User8 from '../../assets/especialistas_testimonios8.jpeg';
import User9 from '../../assets/especialistas_testimonios9.jpeg';
import User10 from '../../assets/especialistas_testimonios10.jpeg';
import User11 from '../../assets/especialistas_testimonios11.jpeg';
import Carousel, { consts } from 'react-elastic-carousel';
import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';

export default function UsersReviews() {

  useEffect(() => {
    let clase = `rec-carousel-item-0`;
    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");
  }, []);

  // const myArrow = ({ type, onClick, isEdge }) => {
  //   const pointer = type === consts.PREV ? PrevButton : NextButton
  //   return (
  //     <div onClick={onClick} disabled={isEdge}>
  //       <img className="nextCarrousel" src={pointer} alt="Next" />
  //     </div>
  //   )
  // }


  useEffect(() => {
    let clase = `rec-carousel-item-0`;
    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");
  }, []);



  // const myArrow = ({ type, onClick, isEdge }) => {
  //   const pointer = type === consts.PREV ? PrevButton : NextButton
  //   return (
  //     <div onClick={onClick} disabled={isEdge}>
  //       <img className="nextCarrousel" src={pointer} alt="Next" />  
  //     </div>
  //   )
  // }


  const setActiveItem = (item) => {

    let clase = `rec-carousel-item-${item.currentItem.index}`;

    let el = document.querySelector(`div.slider_reviews div.${clase}`);
    el.classList.add("slide-activo");

    if ((item.currentItem.index - 1) > -1) {
      let clasePrev = `rec-carousel-item-${item.currentItem.index - 1}`;
      let elPrev = document.querySelector(`div.slider_reviews div.${clasePrev}`);
      elPrev.classList.remove("slide-activo");
    }

    let claseNext = `rec-carousel-item-${item.currentItem.index + 1}`;
    let elNext = document.querySelector(`div.slider_reviews div.${claseNext}`);
    elNext.classList.remove("slide-activo");
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton;
    const clase = type === consts.PREV ? 'prev-custom' : 'next-custom';
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className={"nextCarrousel " + clase} src={pointer} alt="Next" />
      </div>
    )
  }

  return (
    <Carousel renderArrow={myArrow} style={{ marginTop: "3rem" }} className="slider_reviews" enableMouseSwipe={false} itemsToShow={4} pagination={false} onChange={(currentItem) => setActiveItem({ currentItem })}>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Elijo esta técnica ya que la estética que brinda comparada con la ortodoncia tradicional es mucho mejor. Además, puedo notar que mis pacientes se sienten más cómodos y en cada consulta los veo con una gran satisfacción.
            En lo personal como profesional la empresa me brinda mucha comodidad de trabajo al encontrarse en mi misma ciudad y el contacto de trabajo con todo el equipo de LineUp es muy fluido y positivo para poder llevar a cabo cada uno de mis tratamientos.
          </div>
          <div className="review__usuario">Ayelen Acosta</div>
        </div>
        <div className="review__fotodiv">
          <img src={User4} className="review__foto" alt="" />
        </div>
      </div>
      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Cada tratamiento es una experiencia, en donde la sonrisa de mis pacientes es la protagonista gracias a LineUp.</div>
          <div className="review__usuario">Marcela Altamiranda</div>
        </div>
        <div className="review__fotodiv">
          <img src={User5} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">Luego de varios años de trabajar con ortodoncia tradicional, la técnica de alineadores vino a cambiar la ortodoncia. Nos brinda una solución estética diferencial, mucho más cómoda, práctica y llevadera. Mis pacientes se sienten más libres, con menos molestias y disfrutando del tratamiento.
            Como profesional, debo agradecer el apoyo incondicional de la empresa. Siempre están ahí, respondiendo rápido y ayudándome a brindar la mejor atención. En mi consultorio, tengo como vocación la sonrisa de mis pacientes. Y con Line Up formamos un gran equipo. Muchísimas gracias.
          </div>
          <div className="review__usuario">M. Ofelia de Batista</div>
        </div>
        <div className="review__fotodiv">
          <img src={User6} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">"Me sume a line up por la practicidad y comodidad de trabajo, es una solución rápida y de fácil uso para mis pacientes!"
          </div>
          <div className="review__usuario">Laura Leger</div>
        </div>
        <div className="review__fotodiv">
          <img src={User7} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">“Mis pacientes y yo estamos felices tanto con las ventajas de este tipo de tratamientos, como con los resultados obtenidos.
            ¡Te lo recomiendo!”
          </div>
          <div className="review__usuario">Belén De Marta</div>
        </div>
        <div className="review__fotodiv">
          <img src={User8} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">“Trabajar con Line Up me permitió ofrecerle a los pacientes excelentes
            resultados y máxima estética en muy poco tiempo. Le recomiendo a los profesionales probar sus beneficios”

          </div>
          <div className="review__usuario">Geanela Venice</div>
        </div>
        <div className="review__fotodiv">
          <img src={User9} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">
            “Tener la posibilidad de acceder a ortodoncia digital, nos permitirá avanzar y entregar tratamientos super estéticos”
          </div>
          <div className="review__usuario">Florencia Castriciano</div>
        </div>
        <div className="review__fotodiv">
          <img src={User10} className="review__foto" alt="" />
        </div>
      </div>

      <div>
        <div className="review">
          <div className="review__comilla">“</div>
          <div className="review__texto">
            “Trabajar con Line Up es gratificante porque me acerca a mis pacientes. Además puedo diseñar y ver los resultados antes de arrancar un tratamiento.”  </div>
          <div className="review__usuario">Leandro Salado</div>
        </div>
        <div className="review__fotodiv">
          <img src={User11} className="review__foto" alt="" />
        </div>
      </div>
      <div>

      </div>
      <div>
      </div>
      <div>
      </div>
    </Carousel>
  );
}