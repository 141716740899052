import React from 'react';
import { useEffect } from 'react';
import { useMousePosition } from './useMousePosition';
import { Link } from 'react-router-dom';
import Especialistas from '../../assets/especialistas_unfilled.svg';
import EspecialistasF from '../../assets/especialistas_filled.svg';
import Foto1 from '../../assets/especialistas1.jpg';
import Foto2 from '../../assets/especialistas2.jpg';
import Foto3 from '../../assets/especialistas3.jpg';
import Foto4 from '../../assets/nuevopack.jpg';
import Foto5 from '../../assets/especialistas5.jpg';
import Foto6 from '../../assets/images/app2.png';

import Icon1 from '../../assets/slider_especialistas_icon1.svg';
import Icon2 from '../../assets/slider_especialistas_icon2.svg';
import Icon3 from '../../assets/slider_especialistas_icon3.svg';
import Icon4 from '../../assets/slider_especialistas_icon4.svg';
import Icon5 from '../../assets/slider_especialistas_icon5.svg';
import Icon6 from '../../assets/slider_especialistas_icon6.svg';
import Icon7 from '../../assets/slider_especialistas_icon7.svg';
import Icon8 from '../../assets/slider_especialistas_icon8.svg';
import Icon9 from '../../assets/slider_especialistas_icon9.svg';


import '../../styles/components/PreguntasFrecuentes/_faq.scss';
import Intro from '../../assets/faq_especialistas.jpg';
import IntroMobile from '../../assets/faq_especialistas_mobile.jpg';
import Flecha from '../../assets/flecha.svg';

import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';
import Carousel, { consts } from 'react-elastic-carousel';
import UsersReviews from './UsersReviews';
import { Footer } from '../Footer/Footer';
import { SocialMedia } from '../HomeScreen/SocialMedia';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';


export default function SoyOrtodoncista() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 6 },
  ];
  const data = [
    {
      title: '¿Cómo puedo ser parte del staff?',
      paragraph: 'Solo tenés que entrar en la sección Nuestro Staff, completar con tus datos y en breve te vamos a escribir con todo lo necesario para tu admisión.'
    },
    {
      title: '¿Cuáles son las diferencias de los alineadores frente a otros sistemas de ortodoncia?',
      paragraph: 'Se reduce considerablemente el tiempo de sillón de los pacientes, con citas que pueden durar 10 minutos. Además, a diferencia de los brackets, los alineadores son más higiénicos y no se despegan, permitiendo que el seguimiento del tratamiento sea mucho más rápido.'
    },
    {
      title: '¿Cuáles son las diferencias de Line Up frente a otros alineadores?',
      paragraph: 'Nos diferencia la calidad del producto, confeccionado con materiales de alta calidad; la tecnología de las impresiones, para poder brindar un tratamiento sin imprevistos; el soporte técnico realizado por ortodoncistas, para despejar cualquier duda; la facilitación de los entregables, para agilizar los procesos de intercambio; y la información adaptada, para que puedas difundir todo lo que estás haciendo junto a Line Up.'
    },
    {
      title: '¿De qué se trata la adhesión al sistema?',
      paragraph: 'Realizaremos capacitaciones con todo lo necesario para desarrollar a la perfección la técnica con alineadores Line Up. Con un plan de estudio integral, vamos a recorrer cuestiones más básicas como la impresión, hasta la resolución de los casos más complejos, para que cuando finalices puedas ofrecer inmediatamente el servicio de Line Up en tu consultorio.'
    },
    {
      title: '¿Para ser un especialista Line Up tengo que ser ortodoncista?',
      paragraph: 'El conocimiento de la ortodoncia es fundamental para poder realizar la técnica de Line Up. Por eso creemos necesario que nuestros profesionales sean especialistas para poder brindar el mejor servicio posible.'
    },
    {
      title: '¿Cuáles son las etapas del tratamiento?',
      paragraph: 'En la primera etapa, se envían a Line Up una serie de fotografías clínicas para evaluar si tu potencial paciente es una persona apta para recibir el tratamiento. Una vez aprobada, en la segunda etapa, te enviamos todo el packaging del kit Line Up para comenzar con el tratamiento. Además, ante cualquier duda, vas a poder comunicarte en la plataforma online de gestión, por WhatsApp o por vía telefónica.'
    },
    {
      title: '¿Puedo ser un especialista Line Up si vivo en el interior del país?',
      paragraph: '¡Si! a través de la plataforma online de gestión, cada profesional del interior va a poder adherirse a Line Up sin problemas. Van a contar con todos los beneficios que les permitirá seguir sus casos rápidamente, evitando todo tipo de demoras.'
    }
  ]

  class Accordion extends React.Component {
    render() {
      return (
        <div {...{ className: 'wrapper' }}>
          <ul {...{ className: 'accordion-list' }}>
            {data.map((data, key) => {
              return (
                <li {...{ className: 'accordion-list__item', key }}>
                  <AccordionItem {...data} />
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  class AccordionItem extends React.Component {
    state = {
      opened: false
    }

    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this

      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <Helmet>
        <title>Line Up Argentina | Convertite en Experto en Estética Dental</title>
        <meta name="description" content="Te acompañamos con todo lo que necesitas en tus tratamientos y en el desarrollo de tu negocio. ¡Convertite en un experto en alineadores invisibles Line Up!" />
        <link rel="canonical" href="https://line-up.com.ar/nuestros-especialistas" />
      </Helmet>
      <main className="home__container">
        <section className="especialistas__section">
          <nav className="breadcumb-container" >

            <h2 className="formate__titulo">Nuestros Especialistas en placas transparentes</h2>

          </nav>
          {/**foto 1 */}
          <div>
            <div >
              <div>
                <h2 className="especialistas__title spot_mobile">Queremos que seas parte del movimiento que<br />revoluciona la<br />ortodoncia</h2>
                <h2 className="dos-columnas-solo-titulo soyOrtodoncista-titulo-foto1">Queremos que seas parte del <br />movimiento que revoluciona la ortodoncia</h2>
              </div>
            </div>
          </div>
          {/** foto 2 */}
          <div className="dos-columnas porque-boy">
            <div className="dos-columnas-container">
              <div className="columna-izq-container-50">
                <img className='imagen-responsive' src={Foto4} alt="Foto2" />
              </div>
              <div className="columna-der-container-50">
                <div className="soyOrtodoncista__paragraph">
                  <p>Contamos con los últimos avances de la tecnología en salud dental para derribar los mitos y hacer visibles las ventajas de un producto de calidad internacional, con el aval de la comunidad científica y apoyado por un equipo de especialistas en ortodoncia.
                    <br /><br />
                    Por eso queremos que nuestros pacientes y especialistas, en el momento de elegirnos, formen parte de un proceso integral que les brinde comodidad y les ayude día a día a superar sus metas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="especialistas__section parte">
          <div className="parte__imagen"><img className="parte__img" src={Foto6} alt="" /></div>
          <div className="parte__texto">
            <h3>Formá parte de nuestro staff</h3>
            <h2>¡Convertite en un experto en<br /> alineadores invisibles Line Up!</h2>
            <p>No solo vamos a darte todo lo necesario para llevar a cabo los tratamientos con los alineadores, sino que también te acompañaremos en tu camino profesional con herramientas para el desarrollo de tu negocio.<br /><br /> Con la ayuda de una <span>plataforma online de gestión</span>, vas a formar parte de un proceso integral junto a tus pacientes.<br /><br /> Este espacio unifica y agiliza los intercambios necesarios para el desarrollo de los tratamientos: dar de alta, notificar citas, seguimiento y mucho más.</p>
            <Link
              className="button--violeta parte__button"
              to="/sumate"
            >
              Empezá ahora
            </Link>
          </div>
        </section>
        <div className="porque__information especialistas__information ">
          <div className="porque__information-question">¿Por qué ser un especialista Line Up?</div>

          <Carousel itemsToShow={5} breakPoints={breakPoints} renderArrow={myArrow}>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon1} alt="" /></div>
              <h3>Respaldo técnico y profesional</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon2} alt="" /></div>
              <h3>Capacitación permanente</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon3} alt="" /></div>
              <h3>Mayor rentabilidad</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon4} alt="" /></div>
              <h3>Menos tiempo de sillón</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon5} alt="" /></div>
              <h3>Tiempo preciso de tratamiento</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon6} alt="" /></div>
              <h3>Visibilidad del consultorio o clínica </h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon7} alt="" /></div>
              <h3>Soporte para comunicar en redes sociales</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon8} alt="" /></div>
              <h3>Más opciones de tratamientos</h3>
            </div>
            <div className="porque__carousel-item">
              <div><img className="" src={Icon9} alt="" /></div>
              <h3>Sistema digital con poca inversión</h3>
            </div>
          </Carousel>
        </div>

        <div className="especialistas_review_title">Especialistas que eligieron Line Up</div>

        <UsersReviews></UsersReviews>

        <section className="especialistas__section formate">
          <h2 className="formate__titulo">Seguí tu formación con nosotros</h2>
          <p className="formate__texto">Encontrá en Line Up un espacio de capacitación constante y una nueva forma de llegarles a tus pacientes. Sumate a nuestra red de ortodoncistas y actualizá tu carrera profesional.</p>
          <Link
            className="button--violeta formate__button"
            to="/sumate"
          >
            Contactanos
          </Link>
        </section>

        <Helmet>
          <title>Line Up Argentina | ¡Quiero el Mejor Sistema de Ortodoncia!</title>
          <meta name="description" content="Actualizá tu carrera con nuestro sistema revolucionario de ortodoncia, con muchos beneficios para vos. En poco tiempo podés ser parte del Staff Line Up." />
          <link rel="canonical" href="https://line-up.com.ar/preguntas-especialistas" />
        </Helmet>
        <div className="intro intro-preguntas">
          <div className="intro__content">
            <h1 className="intro__subtitulo">¿Qué beneficios tienen los alineadores Line Up?</h1>
            <div className="intro__cta">
              <h2 className="intro__titulo">El movimiento que<br /> revoluciona la<br /> ortodoncia.</h2>
              <div className="enlace-otras-faq">
              </div>
            </div>
            <p className="intro__text">A continuación, algunas preguntas frecuentes que te pueden surgir como profesional de la salud dental.</p>
            <Accordion />
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
}
