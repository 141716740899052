import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { FifthQuestion } from './FifthQuestion';
import { ThirdScreen } from './ThirdScreen'
import arrowBack from '../../assets/play-circle.svg';
import Logo from '../ui/Header/Logo/Logo';
import TypeWriterEffect from 'react-typewriter-effect';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';
export const FourthScreen = () => {

    const [back, setBack] = useState(false);
    const [next, setNext] = useState(false);
    const [question, setQuestion] = useState(false);
    const [selected, setSelected] = useState('');
    const { dispatch } = useContext(AuthContext);

    useEffect(
        () => {
            let timer1 = setTimeout(() => autoNextQuestion(), 7000);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const autoNextQuestion = () => {
        setQuestion(!question);
    }

    const goBack = (e) => {
        e.preventDefault();
        setBack(true);
    }

    const setTreatment = (resp) => {

        setSelected(resp);

        setTimeout(() => {
            setNext(!next);
        }, 500);

        dispatch({
            type: types.saveTest,
            payload: {
                startTreatment: resp
            }
        });
    }


    return (
        <div>

            {
                back &&
                <ThirdScreen />
            }

            <div className="otro-container">
                {
                    !next &&
                    <>
                        <div className="test__logo">
                            <LogoWhite />
                        </div>
                        <img
                            className="test__back-button-screen"
                            onClick={goBack}
                            src={arrowBack}
                            alt="Back"
                        />
                        <div className="test__first-section-salmon">
                            <p>¿Qué tan rápido te gustaría<br />empezar con el tratamiento?</p>
                        </div>

                        <div className="test__row">
                            <div className='test__row-item' onClick={() => setTreatment('ahora')}><div className={selected === 'ahora' ? 'test__first-active-salmon' : 'salmon-hover'} >Ahora mismo</div></div>
                            <div className='test__row-item' onClick={() => setTreatment('semanas')}> <div className={selected === 'semanas' ? 'test__first-active-salmon' : 'salmon-hover'}>En unas semanas</div></div>
                        </div>

                        <div className="test__row">
                            <div className='test__row-item' onClick={() => setTreatment('meses')}><div className={selected === 'meses' ? 'test__first-active-salmon' : 'salmon-hover'}>En unos meses</div></div>
                            <div className='test__row-item' onClick={() => setTreatment('no-sabe')}><div className={selected === 'no-sabe' ? 'test__first-active-salmon' : 'salmon-hover'}> No sé</div></div>
                        </div>

                        <p className="test__paragraph-continue">Elegí una respuesta para continuar</p>
                    </>
                }

                {
                    next &&
                    <FifthQuestion />
                }
            </div>
        </div>
    )
}
