
import Especialistas from '../../assets/porque-full.svg';
import Especialistas2 from '../../assets/porque-full2.jpg';
import Especialistas2m from '../../assets/porque-fullm.png';
import zac from '../../assets/porque2.jpg';
import women from '../../assets/porque3.jpg';

import gum from '../../assets/logos/gum.png';
import atmos2 from '../../assets/logos/atmos2.jpg';
import scheu from '../../assets/logos/scheu.svg';
import odontomix from '../../assets/logos/odontomix.png';
import cozy from '../../assets/logos/cozy.png';
import grimberg from '../../assets/logos/grimberg.png';
import socodonto from '../../assets/logos/socodonto.png';
import '../../styles/components/LineUpLittle/_lineuplittle.scss';
import Carousel, { consts } from 'react-elastic-carousel';
import { Footer } from '../Footer/Footer';
/* import teeth1 from '../../assets/teeth1.jpg';
import teeth2 from '../../assets/teeth2.jpg';
import move from '../../assets/move.svg';
import TwentyTwenty from 'react-twentytwenty'; */
import UsersReviews from '../HomeScreen/UserReview';

import { SocialMedia } from '../HomeScreen/SocialMedia';
import Intro from '../../assets/faq_pacientes.jpg';
import IntroMobile from '../../assets/faq_pacientes_mobile.jpg';
import Little1 from '../../assets/Little1.jpg';
import Little2 from '../../assets/Little2.jpg';
import BarraMulticolor from '../../assets/BarraMulticolor.jpg';
import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';
import Flecha from '../../assets/flecha.svg';
import { Link } from 'react-router-dom';

import Icon1 from '../../assets/slider_porque_icon1.svg';
import Icon2 from '../../assets/slider_porque_icon2.svg';
import Icon3 from '../../assets/slider_porque_icon3.svg';
import Icon4 from '../../assets/slider_porque_icon4.svg';
import Icon5 from '../../assets/slider_porque_icon5.svg';
import Icon6 from '../../assets/slider_porque_icon6.svg';
import Icon7 from '../../assets/slider_porque_icon7.svg';
import Icon8 from '../../assets/slider_porque_icon8.svg';
import Icon9 from '../../assets/slider_porque_icon9.svg';

import chicaHome from '../../assets/chicaHome.jpg';


import React, { useRef, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';




export default function LineUpPlus() {
  const [datos, setDatos] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const carouselRef = useRef(null);
  let resetTimeout;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];

  const data = [
    {
      title: '¿Cuánto dura el tratamiento?',
      paragraph: 'De acuerdo a la complejidad de tu caso puede durar de 6 a 18 meses. Lo determinaremos junto a tu especialista en la primera consulta.'
    },
    {
      title: '¿Cuánto sale el tratamiento?',
      paragraph: 'Todas las sonrisas son distintas, por eso el precio depende del tipo de tratamiento y los honorarios de los profesionales.'
    },
    {
      title: '¿Cómo obtengo los alineadores?',
      paragraph: 'Para recibir el kit Line Up con los alineadores tenés que visitar a un profesional. Ingresá en Encontrá tu Especialista, completá con tu ubicación y coordiná tu turno con el profesional. '
    },
    {
      title: '¿De qué están hechos?',
      paragraph: 'Los alineadores son láminas transparentes, flexibles y super finas, diseñadas y fabricadas exclusivamente para corregir la posición de tus dientes.'
    }

  ]


  class Accordion extends React.Component {
    render() {
      return (
        <div {...{ className: 'wrapper' }}>
          <ul {...{ className: 'accordion-list' }}>
            {data.map((data, key) => {
              return (
                <li {...{ className: 'accordion-list__item', key }}>
                  <AccordionItem {...data} />
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  class AccordionItem extends React.Component {
    state = {
      opened: false
    }

    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this

      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }
  // Simula la carga asíncrona de datos
  useEffect(() => {
    // Tu lógica para cargar datos
    // Ejemplo: fetch('/api/datos').then(response => response.json()).then(data => setDatos(data));
    // Asegúrate de establecer totalPages también
  }, []); // El segundo parámetro es una dependencia vacía, por lo que este efecto se ejecutará solo después del montaje inicial

  useEffect(() => {
    // Este efecto se ejecutará cuando cambien los datos o totalPages
    if (datos && totalPages > 0) {
      // Ahora podemos acceder a carouselRef.current sin preocuparnos por null
      // Realiza operaciones con carouselRef aquí
      // ...

      // Por ejemplo, podrías usar un timeout para simular la espera antes de ejecutar goTo(0)
      resetTimeout = setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goTo(0);
        }
      }, 1500);
    }

    // Limpia el timeout al desmontar el componente o cuando cambie la dependencia
    return () => {
      clearTimeout(resetTimeout);
    };
  }, [datos, totalPages]);



  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }


  return (


    <div>
      <Helmet>
        <title>Line Up Argentina | Nuestro Sistema de Alineadores Invisibles</title>
        <meta name="description" content="A través de placas transparentes, casi invisibles, tus dientes se alinearán sin dolor ni tratamientos complicados. Disfrutá de tu sonrisa sin preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/por-que-line-up" />
      </Helmet>
      <main>

        <div className="intro_plus">
          <img className="plus1_image" src={Little1} alt='Intro' />
        </div>
        <div className="intro">
          <div className="intro__content">
            <h2 className="little1_title">Little Line Up
            </h2>
            <br></br>
            <br></br>
            <p className="plus1_text">Nueva alternativa de ortodoncia con alineadores invisibles
              para pacientes en crecimiento.</p>

            <br></br>
            <p className="plus1_text">Las principales ventajas son:
              <br></br>
              • PRECISIÓN
              <br></br>
              • COMODIDAD
              <br></br>
              • FLEXIBILIDAD: se adaptan a la dinámica familiar
              <br></br>
              • HIGIENE
              <br></br>
              • EFECTIVIDAD</p>
          </div>
        </div>
        <br></br>
        <div className="littleback">
          <div class="dos-columnas">
            <div class="dos-columnas-container">
              <div class="columna-izq-container-50">
                <div className="little2_title">
                  Los alineadores little Line Up se pueden
                  utilizar en dentición primaria y mixta
                  acompañando a cada familia y mejorando
                  la calidad de vida de los niños y niñas
                </div>
                <div className="little2_text">
                  Además, una de las grandes ventajas del área, es la
                  posibilidad de realizar técnicas hibridas, combinadas con
                  ortopedia maxilar para lograr el éxito del tratamiento.
                </div>
                <div className="little2_text">
                  Los especialistas del área están a dispoición para
                  planificar junto al ortodoncista (o a tu ortodoncista) cada
                  caso de los pacientes little.
                </div>
              </div>
              <div class="columna-der-container-50">
                <div >
                  <img className="plus2_image" src={Little2} alt="Hands" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div >
          <img src={BarraMulticolor} alt="Hands" />
        </div>
        <div className="home__container-more-info">
          <div className="home__title-more-info">
            Nuestros partners de la jornada
          </div>
          <Carousel ref={carouselRef} breakPoints={breakPoints} enableAutoPlay
            onNextEnd={({ index }) => {
              if (index + 1 === totalPages) {
                // No es necesario usar setTimeout aquí, ya que la lógica de espera se maneja en el segundo useEffect
                // carouselRef.current.goTo(0);
                setTotalPages(0); // Esto desencadenará el segundo useEffect para realizar la operación después de la espera
              }
            }}>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="atmos__image" src={atmos2} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={odontomix} alt="" /></div>
              <div className="col-partners"></div>
            </div>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={grimberg} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="odontomix" src={cozy} alt="" /></div>
              <div className="col-partners"></div>
            </div>
            <div className="fil-partners">
              <div className="col-partners"></div>
              <div className="col-partners"><img className="gum" src={gum} alt="" /></div>
              <div className="col-partners"></div>
              <div className="col-partners"><img className="socodonto" src={socodonto} alt="" /></div>
              <div className="col-partners"></div>
            </div>

          </Carousel>
        </div>

        <Footer />
      </main>
    </div>
  );
}
