import React, { useEffect } from 'react';
import segundacita from '../../assets/segundacita.png';
import Video from '../../assets/proceso-video.jpg';

export const SecondCard = () => {
    useEffect(() => {
        document.getElementById('proceso').style.background = "#F4D2C6";
    }, []);
    return (
        <>
            <div className="proceso__texto" style={{ transform: 'translateX(25%)' }}>
                <p className="proceso__caption">Digitalizamos tu sonrisa</p>
                <h1 className="proceso__title">Segunda cita</h1>
                <p>Si Line Up es para el paciente, vamos a estudiar el caso a fondo. Agustina describe el ida y vuelta entre los especialistas y Line Up, cómo se toman las impresiones de la boca, el envío de las las muestras y finalmente la fabricación del primer set de alineadores.</p>
                <img
                    className="proceso__video"
                    src={Video}
                    alt="video"
                />
            </div>
            <img
                className="proceso__renderimg"
                src={segundacita}
                alt="volver"
                style={{ width: '50%' }}
            />
        </>
    )
}
