import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/PreguntasFrecuentes/_faq.scss';
import Intro from '../../assets/faq_especialistas.jpg';
import IntroMobile from '../../assets/faq_especialistas_mobile.jpg';
import Flecha from '../../assets/flecha.svg';

import { SocialMedia } from '../HomeScreen/SocialMedia';
import { Footer } from '../Footer/Footer';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

// const paragraph = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet natus sint provident vel ab reprehenderit cum soluta, suscipit facere nisi sed earum repellendus fuga debitis, nam molestiae minima voluptates possimus.'

const data = [
  {
    title: '¿Cómo puedo ser parte del staff?',
    paragraph: 'Solo tenés que entrar en la sección Nuestro Staff, completar con tus datos y en breve te vamos a escribir con todo lo necesario para tu admisión.'
  },
  {
    title: '¿Cuáles son las diferencias de los alineadores frente a otros sistemas de ortodoncia?',
    paragraph: 'Se reduce considerablemente el tiempo de sillón de los pacientes, con citas que pueden durar 10 minutos. Además, a diferencia de los brackets, los alineadores son más higiénicos y no se despegan, permitiendo que el seguimiento del tratamiento sea mucho más rápido.'
  },
  {
    title: '¿Cuáles son las diferencias de Line Up frente a otros alineadores?',
    paragraph: 'Nos diferencia la calidad del producto, confeccionado con materiales de alta calidad; la tecnología de las impresiones, para poder brindar un tratamiento sin imprevistos; el soporte técnico realizado por ortodoncistas, para despejar cualquier duda; la facilitación de los entregables, para agilizar los procesos de intercambio; y la información adaptada, para que puedas difundir todo lo que estás haciendo junto a Line Up.'
  },
  {
    title: '¿De qué se trata la adhesión al sistema?',
    paragraph: 'Realizaremos capacitaciones con todo lo necesario para desarrollar a la perfección la técnica con alineadores Line Up. Con un plan de estudio integral, vamos a recorrer cuestiones más básicas como la impresión, hasta la resolución de los casos más complejos, para que cuando finalices puedas ofrecer inmediatamente el servicio de Line Up en tu consultorio.'
  },
  {
    title: '¿Para ser un especialista Line Up tengo que ser ortodoncista?',
    paragraph: 'El conocimiento de la ortodoncia es fundamental para poder realizar la técnica de Line Up. Por eso creemos necesario que nuestros profesionales sean especialistas para poder brindar el mejor servicio posible.'
  },
  {
    title: '¿Cuáles son las etapas del tratamiento?',
    paragraph: 'En la primera etapa, se envían a Line Up una serie de fotografías clínicas para evaluar si tu potencial paciente es una persona apta para recibir el tratamiento. Una vez aprobada, en la segunda etapa, te enviamos todo el packaging del kit Line Up para comenzar con el tratamiento. Además, ante cualquier duda, vas a poder comunicarte en la plataforma online de gestión, por WhatsApp o por vía telefónica.'
  },
  {
    title: '¿Puedo ser un especialista Line Up si vivo en el interior del país?',
    paragraph: '¡Si! a través de la plataforma online de gestión, cada profesional del interior va a poder adherirse a Line Up sin problemas. Van a contar con todos los beneficios que les permitirá seguir sus casos rápidamente, evitando todo tipo de demoras.'
  }
]

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class AccordionItem extends React.Component {
  state = {
    opened: false
  }

  render() {
    const {
      props: {
        paragraph,
        title
      },
      state: {
        opened
      }
    } = this

    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {title}
          </h3>
          <span {...{ className: 'accordion-item__icon' }} />
        </div>
        <div {...{ className: 'accordion-item__inner' }}>
          <div {...{ className: 'accordion-item__content' }}>
            <p {...{ className: 'accordion-item__paragraph' }}>
              {paragraph}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default function Especialistas() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return <div>
    <Helmet>
      <title>Line Up Argentina | ¡Quiero el Mejor Sistema de Ortodoncia!</title>
      <meta name="description" content="Actualizá tu carrera con nuestro sistema revolucionario de ortodoncia, con muchos beneficios para vos. En poco tiempo podés ser parte del Staff Line Up." />
      <link rel="canonical" href="https://line-up.com.ar/preguntas-especialistas" />
    </Helmet>
    <div className="intro">
      <img className="intro__img" src={Intro} alt="Intro" />
      <div><img className="intro__img--mobile" src={IntroMobile} alt='Intro' /></div>
      <div className="intro__content">
        <Link
          className="button--violet parte__button intro__button mobile"
          to="/preguntas-pacientes"
        >
          <div style={{ paddingRight: '15px' }}>Ver preguntas para pacientes </div> <div> <img className="button-icon-felcha" src={Flecha} alt='ver' /></div>
        </Link>
        <h1 className="intro__subtitulo">¿Qué beneficios tienen los alineadores Line Up?</h1>
        <div className="intro__cta">
          <h2 className="intro__titulo">El movimiento que<br /> revoluciona la<br /> ortodoncia.</h2>
          <div className="enlace-otras-faq">
            <Link
              className="button--violet parte__button intro__button desktop"
              to="/preguntas-pacientes"
            >
              <div style={{ paddingRight: '15px' }}>Ver preguntas para pacientes </div> <div> <img className="button-icon-felcha" src={Flecha} alt='ver' /></div>
            </Link>
          </div>
        </div>
        <p className="intro__text">A continuación, algunas preguntas frecuentes que te pueden surgir como profesional de la salud dental.</p>
        <Accordion />
      </div>
    </div>

    <section className="especialistas__section formate conoce-mas conoce-mas-esp ">
      <h2 className="formate__titulo">Descubrí el tratamiento que revoluciona la ortodoncia.</h2>
      <p className="formate__texto">Trabajamos con especialistas en este campo que actualizaron su carrera profesional, y encontraron en Line Up, un espacio de capacitación constante y una nueva forma de llegar a sus pacientes. <br /><br />¿Te interesa seguir tu formación con nosotros?</p>
      <Link
        className="button--violet formate__button"
        to="/nuestros-especialistas"
      >
        Conocé más
      </Link>
    </section>

    <SocialMedia />
    <Footer />

  </div>;
}
