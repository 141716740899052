import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { FourthScreen } from './FourthScreen';
import { SecondScreen } from './SecondScreen';
import arrowBack from '../../assets/play-circle.svg';
import Logo from '../ui/Header/Logo/Logo';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';
import TypeWriterEffect from 'react-typewriter-effect';
export const ThirdScreen = () => {

    const [back, setBack] = useState(false);
    const [next, setNext] = useState(false);
    const [question, setQuestion] = useState(false);
    const [selected, setSelected] = useState('');
    const { dispatch } = useContext(AuthContext);

    useEffect(
        () => {
            let timer1 = setTimeout(() => autoNextQuestion(), 4000);
            return () => {
                clearTimeout(timer1);
            };
        },
        []
    );

    const autoNextQuestion = () => {
        setQuestion(!question);
    }

    const goBack = (e) => {
        e.preventDefault();
        setBack(!back);
    }

    const setBrackets = (resp) => {

        setSelected(resp);

        setTimeout(() => {
            setNext(!next);
        }, 500);

        dispatch({
            type: types.saveTest,
            payload: {
                brackets: resp
            }
        });
    }

    return (
        <div>

            {
                back &&
                <SecondScreen />
            }

            <div>
                {
                    !next &&
                    <div className="otro-container">
                        <div className="test__logo">
                            <LogoWhite />
                        </div>
                        <img
                            className="test__back-button-screen"
                            onClick={goBack}
                            src={arrowBack}
                            alt="Back"
                        />
                        <div className="test__first-section-green">
                            <h3 className="test__title-description">¿Alguna vez usaste brackets?</h3>
                        </div>

                        <div className="test__options-container">
                            <div className='test__option' onClick={() => setBrackets('si')}><div className={selected === 'si' ? 'test__first-active-green' : 'green-hover'}>Sí</div></div>
                            <div className='test__option' onClick={() => setBrackets('no')}><div className={selected === 'no' ? 'test__first-active-green' : 'green-hover'}>No</div></div>
                        </div>

                        <p className="test__paragraph-continue">Elegí una respuesta para continuar</p>
                    </div>
                }

                {
                    next &&
                    <FourthScreen />
                }
            </div>
        </div>
    )
}
