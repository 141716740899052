import React from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import AppRouter from './routers/AppRouter';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-190457805-1');

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false };
}

export default function OdontologyApp() {

  const [user, dispatch] = useReducer(authReducer, {}, init);

  useEffect(() => {

    localStorage.setItem('user', JSON.stringify(user));
    
  }, [user]);



  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      <AppRouter />
    </AuthContext.Provider>
  );
}
