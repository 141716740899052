import React from 'react';
import { Link } from 'react-router-dom';

export const SearchInMap = (props) => {

    const classBlanco = props.fondoBlanco ? 'fondo-blanco' : '';
    const vamapa = !props.fondoBlanco;

    return (
        <div className="home__map-container">
            
            { vamapa &&
            <Link to="/encontrar-especialista" style={{width: '100%'}}>
            <div className="home__satelite">
                
                <form>
                    <div className="home__row-input">
                        <input
                            type="text"
                            placeholder="Buscá tu especialista Line Up por tu zona"
                            className="input home__input-map"
                        />
                        <button
                            className="home__search-button"
                        >
                            Buscar
                        </button>
                    </div>
                </form>
               
            </div>
             </Link>
            }
            { vamapa &&
            <div className="home__container-find-specialist">
                <div className="home__container-find-specialist-center">
                <h4 className="home__find-specialist-title">Encontrá un especialista cerca tuyo.</h4>
                <div className="home__find-specialist-detail">
                    <p>
                    Nuestros especialistas son ortodoncistas que han actualizado su carrera profesional a esta nueva forma de salud dental. Contamos con la tecnología y el equipo de profesionales necesario para brindar la mejor calidad de producto posible y al mejor precio.
                    </p>
                </div>
                </div>
            </div>
            }{/*}  Viene de SoyPaciente*/}     
            { !vamapa &&    
                        <div className="home__container-find-specialist" style={{background: '#EFEFEF'}}>
                        <div className="home__container-find-specialist-center">
                        <h4 className="home__find-specialist-title">Encontrá un especialista cerca tuyo.</h4>
                        <div className="home__find-specialist-detail">
                        Contamos con la tecnología y el equipo de profesionales necesario para brindar la mejor calidad de producto posible y al mejor precio.
                        </div>
                        <Link to="/encontrar-especialista">
                        <form>
                        <div className="home__row-input home__row-input-alt">
                        <div>
                        <input
                            type="text"
                            placeholder="Buscá tu especialista Line Up por tu zona"
                            className="input home__input-map"
                        /></div>
                        <div>
                        <button
                            className="home__search-button"
                        >
                            Buscar
                        </button></div>
                    </div>
                </form>
                  </Link>                
                        </div>
                    </div>
            }
         
            <div className={"home__container-more-info " + classBlanco}>
                <div className="home__title-more-info">
                    ¿Querés saber más?
                </div>
                <Link
            className="button--violeta formate__button"
            to="/sumate"
          >
            Contactanos
        </Link>
{/* 
                <div className="home__mail-description">
                    Dejanos tu mail y código postal para poder asesorarte de la mejor manera.
                </div>
                <div className="sabermas-form">
                <form className="p-20 formusaber">

                <div className="home__row-inputm">
                    <input
                        type="text"
                        placeholder="Mail"
                        className="input"
                    />
                    <input
                        type="text"
                        placeholder="Código postal"
                        className="input"
                    />
                </div>

                <button
                    className="home__button-send"
                >
                    Enviar
                </button>
                </form>
                </div> */}
            </div>

   
        </div>
    )
}