import React from 'react';
import Especialistas from '../../assets/porque-full.svg';
import Especialistas2 from '../../assets/porque-full2.jpg';
import Especialistas2m from '../../assets/porque-fullm.png';
import zac from '../../assets/porque2.jpg';
import women from '../../assets/porque3.jpg';

import Carousel, { consts } from 'react-elastic-carousel';
import { Footer } from '../Footer/Footer';
/* import teeth1 from '../../assets/teeth1.jpg';
import teeth2 from '../../assets/teeth2.jpg';
import move from '../../assets/move.svg';
import TwentyTwenty from 'react-twentytwenty'; */
import UsersReviews from '../HomeScreen/UserReview';

import { SocialMedia } from '../HomeScreen/SocialMedia';

import NextButton from '../../assets/next.svg';
import PrevButton from '../../assets/prev.svg';

import Icon1 from '../../assets/slider_porque_icon1.svg';
import Icon2 from '../../assets/slider_porque_icon2.svg';
import Icon3 from '../../assets/slider_porque_icon3.svg';
import Icon4 from '../../assets/slider_porque_icon4.svg';
import Icon5 from '../../assets/slider_porque_icon5.svg';
import Icon6 from '../../assets/slider_porque_icon6.svg';
import Icon7 from '../../assets/slider_porque_icon7.svg';
import Icon8 from '../../assets/slider_porque_icon8.svg';
import Icon9 from '../../assets/slider_porque_icon9.svg';

import chicaHome from '../../assets/chicaHome.jpg';


import { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';



export default function PorQue() {
  const data = [
    {
      title: '¿Cuánto dura el tratamiento?',
      paragraph: 'De acuerdo a la complejidad de tu caso puede durar de 6 a 18 meses. Lo determinaremos junto a tu especialista en la primera consulta.'
    },
    {
      title: '¿Cuánto sale el tratamiento?',
      paragraph: 'Todas las sonrisas son distintas, por eso el precio depende del tipo de tratamiento y los honorarios de los profesionales.'
    },
    {
      title: '¿Cómo obtengo los alineadores?',
      paragraph: 'Para recibir el kit Line Up con los alineadores tenés que visitar a un profesional. Ingresá en Encontrá tu Especialista, completá con tu ubicación y coordiná tu turno con el profesional. '
    },
    {
      title: '¿De qué están hechos?',
      paragraph: 'Los alineadores son láminas transparentes, flexibles y super finas, diseñadas y fabricadas exclusivamente para corregir la posición de tus dientes.'
    }
  
  ]
  
  
  class Accordion extends React.Component {
    render() {
      return (
        <div {...{ className: 'wrapper' }}>
          <ul {...{ className: 'accordion-list' }}>
            {data.map((data, key) => {
              return (
                <li {...{ className: 'accordion-list__item', key }}>
                  <AccordionItem {...data} />
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }
  
  class AccordionItem extends React.Component {
    state = {
      opened: false
    }
  
    render() {
      const {
        props: {
          paragraph,
          title
        },
        state: {
          opened
        }
      } = this
  
      return (
        <div
          {...{
            className: `accordion-item, ${opened && 'accordion-item--opened'}`,
            onClick: () => { this.setState({ opened: !opened }) }
          }}
        >
          <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {title}
            </h3>
            <span {...{ className: 'accordion-item__icon' }} />
          </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);



  /*   const [width, setWidth] = useState(0); */

  useEffect(() => {

    /*     const updateWindowDimensions = () => { */
    /*       const newWidth = window.innerWidth; */
    /*       setWidth(newWidth); */
    /*       console.log("updating Width "+width); */
    /*     }; */
    /*  */
    /*     window.addEventListener("resize", updateWindowDimensions); */

    /*
        const spotlight = document.querySelector('.spotlightporque');
        const spoty = spotlight.getBoundingClientRect().top;
        const spotyf = spotlight.getBoundingClientRect().bottom;
    
    
        if (position.y > (spoty + 40) && position.y < (spotyf - 40) ) {
          let avance = 180 / 40;
          var newSize;
          const diff = (spotyf + spoty) / 2;
    
          if (position.y < (diff) ) {
            console.log('aca');
             newSize = ( position.y - (spoty + 40)) * avance;
          }
          if (position.y > ( diff)) {
            newSize = (   (spotyf - 40) - position.y) * avance;
         }
         if(newSize > 180) {
          newSize = 180;
         }
    
          spotlight.style.backgroundSize = `100%, ${newSize}px, 100%`;
        }
    
        if (position.y > spoty && position.y < spotyf) {
          spotlight.style.backgroundPosition = `left, ${(position.x - (newSize/2))}px ${(position.y - spoty) - (newSize/2)}px, left `;
        } else {
          if (window.innerWidth < 1650) {
            spotlight.style.backgroundPosition = `left, 70% 75%, left `;
          } else {
            spotlight.style.backgroundPosition = `left, 600px 75%, left `;
          }
      }*/

    /*     return () => window.removeEventListener("resize", updateWindowDimensions); */

  });

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? PrevButton : NextButton
    return (
      <div onClick={onClick} disabled={isEdge}>
        <img className="nextCarrousel" src={pointer} alt="Next" />
      </div>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3, itemsToScroll: 3, pagination: true },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 5, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 6 },
  ];
  return (
    <div>
      <Helmet>
        <title>Line Up Argentina | Nuestro Sistema de Alineadores Invisibles</title>
        <meta name="description" content="A través de placas transparentes, casi invisibles, tus dientes se alinearán sin dolor ni tratamientos complicados. Disfrutá de tu sonrisa sin preocupaciones." />
        <link rel="canonical" href="https://line-up.com.ar/por-que-line-up" />
      </Helmet>
      <main>
        <div className="porque__container">
          <h1 className="porque__question">¿Por qué usar Alineadores Invisibles Line Up?</h1>          
          
            <div class="dos-columnas">
              <div class="dos-columnas-container">
                <div class="columna-izq-container-60">
                  <h2 class="dos-columnas-solo-titulo">Alineadores <br></br>invisibles <br></br>para elegir<br></br> tu mejor sonrisa</h2>
                </div>
                <div class="columna-der-container-40">
                  <div className="lafotitocont">
                    <img className="lafotito" src={chicaHome} alt="Especialistas" />
                  </div>
                </div>
              </div>
            </div>

          <div class="dos-columnas porque-boy">
            <div class="dos-columnas-container">
              <div class="columna-izq-container-40">
                <img class="imagen-responsive" src={zac} alt="Zac"/>
              </div>
              <div class="columna-der-container-60">
                <div className="porque__paragraph">
                  <h3>Las sonrisas tienen algo para contar y todas son diferentes, queremos que elijas la tuya sin preocuparte por nada más.</h3>
                  <p>A través de placas transparentes, casi invisibles, tus dientes se alinearán de una forma parecida a la ortodoncia tradicional, pero sin dolor ni tratamientos complicados.</p>              </div>
              </div>
            </div>
          </div>


          <div className="porque__information">
            <div className="porque__information-question">¿Por qué usar Line Up?</div>

            <Carousel itemsToShow={5} itemsToScroll={5} enableMouseSwipe={true} breakPoints={breakPoints} renderArrow={myArrow}>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon1} alt="" /></div>
                <h3>Ya sabes el resultado</h3>
                <p>La tecnología posibilita ver los resultados antes de iniciar el tratamiento.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon2} alt="" /></div>
                <h3>Libertad de limpieza</h3>
                <p>Placas removibles que permiten un mejor lavado de los dientes en los momentos necesarios.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon3} alt="" /></div>
                <h3>No hay alambres</h3>
                <p>Material flexible que se adapta completamente a la superficie de la boca sin lastimar.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon4} alt="" /></div>
                <h3>Comé lo que quieras</h3>
                <p>Se pueden retirar las placas para disfrutar completamente de las comidas.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon5} alt="" /></div>
                <h3>Elegí tu sonrisa</h3>
                <p>Transparentes casi invisibles y removibles para elegir cuándo usarlos.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon6} alt="" /></div>
                <h3>Descansá tus músculos</h3>
                <p>También funcionan como férulas de descanso para el bruxismo.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon7} alt="" /></div>
                <h3>Seguí practicando</h3>
                <p>Gracias a su materialidad y flexibilidad, los alineadores se pueden usar en la mayoría de los deportes.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon8} alt="" /></div>
                <h3>Más tiempo para vos</h3>
                <p>El proceso de alineamiento es mucho más rápido que el resto de los tratamientos.</p>
              </div>
              <div className="porque__carousel-item">
                <div><img className="" src={Icon9} alt="" /></div>
                <h3>Dientes más sanos</h3>
                <p>No daña la superficie del esmalte de los dientes.</p>
              </div>
            </Carousel>
          </div>
        
          <h1 className="porque_titulo_reviews">Eligieron Line Up, eligen su sonrisa</h1>
          <UsersReviews />                 
        </div>
        <Footer />
      </main>
    </div>
  );
}
