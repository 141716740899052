import React from 'react';
import Logoimg from './assets/logo.png';
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/">
      <div className="logo">
      <img id="logo" src={Logoimg} alt="Logo" />
    </div>
    </Link>
  );
}
