import React from 'react';
import Girl from '../../assets/girl.jpg';
import webImage1 from '../../assets/web_image_1.png';
import Boy from '../../assets/boy.jpg';

export const SectionExperience = () => {
    return (
        <>
            <div className="home__figure-container">
                <div className="home__images-container">
                    <img 
                        className="home__girl-image"
                        src={webImage1} 
                        alt="A girl" 
                    />                    
                </div>

                <div className="home__description-container desktop">
                    <h2 className="home__description-title">Vas a vivir una experiencia distinta a los tratamientos de ortodoncia tradicionales.</h2>
                    <p>
                        Los alineadores mejoran tu calidad de vida y te permitirán mostrar la sonrisa que quieras y cuando quieras. Además, Line Up tiene calidad internacional a precios accesibles. <br/> <br/> Creemos en la importancia de poder expresarnos con las sonrisas. No existe una sola, todas son distintas y nos pertenecen. Tenemos que mostrarlas con seguridad, identidad, en cualquier momento de nuestra rutina y de la manera más saludable posible, y así sentir que estamos haciendo lo mejor para nuestras vidas.
                    </p>
                    <h2 className="home__description-seguimiento">Seguimiento online con actualizaciones según tu tratamiento</h2>
                    
                </div>
            </div>
        </>
    )
}
