import React, { useEffect } from "react";
// import { useContext } from 'react';
import { NavLink } from "react-router-dom";
import Logo from "../Logo/Logo";
import LogoPlus from "../Logo/LogoPlus";
import LogoLittle from "../Logo/LogoLittle";
import isnta from '../Logo/assets/insta.png';
import face from '../Logo/assets/face.png';
import { constants } from '../../../../constants/constants.js';

// import { useHistory } from 'react-router-dom';
// import { AuthContext } from '../../../../auth/AuthContext';
// import { types } from '../../../../types/types';

const highlightColor = '#3A6981';

const handleOpenLink = (url) => {
  window.open(url, '_blank');
}

export const Navbar = (props) => {
  // const { dispatch } = useContext(AuthContext);

  // const history = useHistory();

  // const handleLogout = () => {

  //   history.replace('/');

  //   dispatch({
  //     type: types.logout
  //   });
  // }



  useEffect(() => {
    const btnMenu = document.querySelector(".navbar__btn-menu");
    if (btnMenu) {

      btnMenu.addEventListener("click", () => {
        const menuItems = document.querySelector(".navbar__menu-items");
        menuItems.classList.toggle("navbar__show");

      });


      const navbar__items = document.querySelectorAll('.navbar__item');

      [].forEach.call(navbar__items, function (navbar__item) {
        // do whatever
        navbar__item.addEventListener("click", () => {
          const menuItems = document.querySelector(".navbar__menu-items");
          menuItems.classList.toggle("navbar__show");
        });
      });

    }
  }, []);

  /*   const scrollState = props.scrollState; */

  return (

    <nav className="navbar__container">
      <div className="navbar__logo">
        <Logo />
      </div>
      <div className="navbar__logo">
        <LogoLittle />
      </div>
      <div className="navbar__logo">
        <LogoPlus />
      </div>
      {/* { scrollState && */}
      <ul className="navbar__menu-items">
        <div className="navbar__logo navbar__logo-bis navbar__item">
          <Logo />
          <div className="navbar__close">X</div>
        </div>
        <li className="navbar__item">
          <NavLink to="/soyOrtodoncista" activeStyle={{ color: highlightColor }}>Soy Ortodoncista</NavLink>
        </li>
        <li className="navbar__item">
          <NavLink to="/soyPaciente" activeStyle={{ color: highlightColor }}>Soy Paciente</NavLink>
        </li>
        {/*   <li className="navbar__item">
          <NavLink to="/encontrar-especialista" activeStyle={{ color: highlightColor }}>Cursos</NavLink>
        </li>*/}
        <li className="navbar__item">
          <NavLink className="button--rainbow test__button header-button-1" to="/test">
            <div>
              <p>¿Es para mi?</p>
            </div>
          </NavLink>
        </li>
        <li className="navbar__item">
          <NavLink className="button--rainbow test__button header-button-2" to={{ pathname: "http://plataforma.line-up.com.ar" }} target="_blank">
            <div>
              <p>Plataforma</p>
            </div>
          </NavLink>
        </li>
        {/* LOGIN/LOGOUT */}
        {/* {
          !user.logged &&
          <li>
            <NavLink to="/auth/login">Login</NavLink>
          </li>
        }

        {
          user.logged &&
          <li>Bienvenido {user.name} <button onClick={handleLogout}>Logout</button></li>
        } */}
      </ul>
      <section className='redes'>
        <a onClick={e => { e.preventDefault(); handleOpenLink(constants.instagram); }}>  <img src={isnta} className="icono_redes redes_inta" alt="instagram" /></a>
        <a onClick={e => { e.preventDefault(); handleOpenLink(constants.facebook); }}> <img src={face} className="icono_redes redes_face" alt="faceboock" /></a>
      </section>
      <span className="navbar__btn-menu">
        <i className="fas fa-bars"></i>
      </span>
    </nav>

  );
};
