import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import { SecondScreen } from './SecondScreen';
import { StartTest } from './StartTest';
import arrowBack from '../../assets/play-circle.svg';
import amontonados from '../../assets/amontonados.png';
import separados from '../../assets/separados.png';
import salientes from '../../assets/salientes.png';
import playCircleNext from '../../assets/play-circle-next.svg';
import Logo from '../ui/Header/Logo/Logo';
import TypeWriterEffect from 'react-typewriter-effect';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';

export const FirstScreen = () => {
    const { dispatch } = useContext(AuthContext);
    const [goBack, setGoBack] = useState(false);
    const [secondQuestion, setSecondQuestion] = useState(false);
    const [next, setNext] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        document.getElementById('header').style.display = 'none';
    }, []);

    const teethDescription = (teethType = '') => {
        let selectedOption = selected;
        selectedOption.push(teethType);
        setSelected(selectedOption);

        dispatch({
            type: types.saveTest,
            payload: {
                teethDescription: teethType
            }
        });
    }

    const nextPage = (e) => {
        e.preventDefault();
        setNext(!next);
    }

    const goFirstScreen = (e) => {
        e.preventDefault();
        setGoBack(!goBack);
    }



    const nextQuestion = (e) => {
        e.preventDefault();
        setSecondQuestion(!secondQuestion);
    }

    return (
        <div className='qconte' >
            
            {
                !next && !goBack &&
                <>
                     <div className="test__logo">
                        <LogoWhite /> 
                    </div>
                        <div
                            className="test__first-question"
                            onClick={nextQuestion}
                        >                          
                            <img
                                className="test__back-button"
                                onClick={goFirstScreen}
                                src={arrowBack}
                                alt="Back"
                            />
                            <p> </p>
                        </div>
                    

                            
                            <img
                                className="test__back-button-screen"
                                onClick={goFirstScreen}
                                src={arrowBack}
                                alt="Back"
                            />
                            <div className="test__first-section-purple">
                                <div className="test__title-description">
                                    ¿Cómo describirías tus dientes?
                                </div>
                                <p>Elegí las que apliquen para continuar</p>
                            </div>

                            <div className="test__teeth-type-container">
                                <div className="test__teeth-type">
                                    <img onClick={e => {
                                            e.preventDefault();
                                            teethDescription('amontonados');
                                        }} src={amontonados} alt="Amontonados" />
                                    <p
                                        className={selected.includes('amontonados') ? 'test__first-active' : ''}
                                        onClick={e => {
                                            e.preventDefault();
                                            teethDescription('amontonados');
                                        }}>
                                        Amontonados
                                    </p>
                                </div>
                                <div className="test__teeth-type">
                                    <img  onClick={e => {
                                            e.preventDefault();
                                            teethDescription('separados');
                                        }} src={separados} alt="Separados" />
                                    <p
                                        className={selected.includes('separados') ? 'test__first-active' : ''}
                                        onClick={e => {
                                            e.preventDefault();
                                            teethDescription('separados');
                                        }}> Separados</p>
                                </div>
                                <div className="test__teeth-type">
                                    <img onClick={e => {
                                            e.preventDefault();
                                            teethDescription('salientes');
                                        }} src={salientes} alt="Salientes" />
                                    <p
                                        className={selected.includes('salientes') ? 'test__first-active' : ''}
                                        onClick={e => {
                                            e.preventDefault();
                                            teethDescription('salientes');
                                        }}>Salientes</p>
                                </div>
                            </div>

                            <div
                                className="test__next-button-first"
                                onClick={nextPage}
                            >
                                <p style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Siguiente</p>
                                <img src={playCircleNext} alt="PlayCircleNext" />
                            </div>
                        </>
                    }

                

            {
                next && !goBack &&
                <SecondScreen />
            }

            {
                goBack &&
                <StartTest />
            }
        </div>
    )
}
