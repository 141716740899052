import React, { useContext } from 'react'
import { useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { useForm } from '../../hooks/useForm';
import { types } from '../../types/types';
import { Success } from './Success';
import LogoWhite from '../ui/Header/LogoWhite/LogoWhite';

export const EighthScreen = () => {

    const { dispatch } = useContext(AuthContext);
    const [isSended, setIsSended] = useState(false);

    const initialForm = {
        name: '',
        email: '',
        cp: '',
        phone: ''
    };

    const [formValues, handleInputChange] = useForm(initialForm);

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorPhone, setErrorPhone] = useState("");
    const [errorCp, setErrorCp] = useState("");

    const saveForm = (e) => {
        e.preventDefault();

        setErrorName('');
        setErrorEmail('');
        setErrorCp('');
        setErrorPhone('');

        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValues.name == "" || formValues.email == "" || !pattern.test(formValues.email) || formValues.cp == '' || formValues.phone == "") {
            if (formValues.name == "") {
                setErrorName('El campo Nombre y Apellido es obligatorio');
            }
            if (formValues.email == "") {
                setErrorEmail('El campo Email es obligatorio');
            }
            if (!pattern.test(formValues.email)) {
                setErrorEmail('El Email debe ser válido');
            }
            if (formValues.cp == "") {
                setErrorCp('El CP es obligatorio.');
            }
            if (formValues.phone == "") {
                setErrorPhone('El Celular es obligatorio.');
            }
        } else {
            dispatch({
                type: types.saveTest,
                payload: {
                    userData: formValues
                }
            });
            setIsSended(!isSended);
        }
    }


    return (

        <div>
            <div className="test__form-title">Line up</div>
            {
                isSended
                    ? (<Success />)
                    : (
                        <div className="test__form-container">
                            <h3>Completá tus datos</h3>

                            <p>Te enviaremos una lista con los profesionales Line Up cerca tuyo.</p>

                            <form className="test__form-column">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Nombre y Apellido"
                                    name="name"
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorName}</div>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Mail"
                                    name="email"
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorEmail}</div>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Código postal"
                                    name="cp"
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px' }}>{errorCp}</div>
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Celular"
                                    name="phone"
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <div style={{ paddingLeft: '1rem', color: 'red', fontSize: '12px', marginBottom: '1rem' }}>{errorPhone}</div>

                                <button onClick={saveForm}>Enviar</button>
                            </form>
                        </div>
                    )
            }


        </div>
    )
}
