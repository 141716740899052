import React from 'react';
import ReactDOM from 'react-dom';
import OdontologyApp from './OdontologyApp';

import './styles/styles.scss';

ReactDOM.render(
  <OdontologyApp />,
  document.getElementById('root'),
);
